import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FAQ2.module.css";

const FAQ2: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={styles.faq7}>
      <div className={styles.maxWidth}>
        <div className={styles.sectionTitle}>
          <h1 className={styles.heading}>Questions</h1>
          <div className={styles.headerContent}>
            Trouvez des réponses aux questions courantes sur la fonctionnalité,
            l'intégration et la maintenance des chatbots IA.
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.listItem}>
            <b className={styles.question}>Comment fonctionnent les chatbots ?</b>
            <div className={styles.textContent}>
              Les chatbots utilisent le traitement du langage naturel et des algorithmes
              d'apprentissage automatique pour comprendre les entrées des utilisateurs
              et fournir des réponses pertinentes. Ils peuvent être intégrés à diverses
              plateformes et programmés pour effectuer des tâches spécifiques.
            </div>
          </div>
          <div className={styles.listItem1}>
            <b className={styles.question1}>Quels sont les avantages de l'utilisation des chatbots ?</b>
            <div className={styles.text}>
              Les chatbots peuvent améliorer le service client en fournissant des réponses
              instantanées et une assistance personnalisée. Ils peuvent également automatiser
              les tâches répétitives, ce qui permet de gagner du temps et des ressources.
            </div>
          </div>
          <div className={styles.listItem2}>
            <b className={styles.question2}>Les chatbots peuvent-ils être personnalisés ?</b>
            <div className={styles.text1}>
              Oui, les chatbots peuvent être personnalisés pour correspondre à l'image de
              marque et au ton d'une entreprise. Ils peuvent également être formés à comprendre
              le vocabulaire spécifique à une industrie.
            </div>
          </div>
          <div className={styles.listItem3}>
            <b className={styles.question3}>Comment les chatbots peuvent-ils être intégrés ?</b>
            <div className={styles.text2}>
              Les chatbots peuvent être intégrés à des sites Web, des plateformes de messagerie
              et des applications mobiles. Ils peuvent également être connectés à des systèmes
              backend pour accéder et récupérer des données.
            </div>
          </div>
          <div className={styles.listItem4}>
            <b className={styles.question4}>Les chatbots sont-ils faciles à maintenir ?</b>
            <div className={styles.text3}>
              Une fois configurés, les chatbots nécessitent une surveillance et des mises à jour
              régulières pour garantir des performances optimales. Cependant, ils peuvent réduire
              considérablement le besoin de support client manuel.
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.content1}>
            <h1 className={styles.heading1}>Vous avez encore des questions ?</h1>
            <div className={styles.link}>Contactez-nous pour plus d'informations.</div>
          </div>
          <button
            className={styles.stylesecondarySmallfalse}
            onClick={onStyleSecondarySmallFalseClick}
          >
            <div className={styles.button}>Juste ici</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FAQ2;
