import { FunctionComponent, ChangeEvent, useMemo, type CSSProperties } from "react";
import styles from "./Inputs.module.css";

type InputsType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void; // Ajout de onChange

  /** Style props */
  propOverflow?: CSSProperties["overflow"];
  propOverflow1?: CSSProperties["overflow"];
};

const Inputs: FunctionComponent<InputsType> = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  propOverflow,
  propOverflow1,
  onChange // Ajout de onChange
}) => {
  const typeDefaultStyle: CSSProperties = useMemo(() => {
    return {
      overflow: propOverflow,
    };
  }, [propOverflow]);

  const typeDefault1Style: CSSProperties = useMemo(() => {
    return {
      overflow: propOverflow1,
    };
  }, [propOverflow1]);

  return (
    <div className={styles.inputs}>
      {firstName && (
        <div className={styles.input}>
          <div className={styles.firstName}>{firstName}</div>
          <input
            className={styles.typedefault}
            type="text"
            style={typeDefaultStyle}
            name="firstName"
            onChange={onChange} // Utilisation de onChange
          />
        </div>
      )}
      {lastName && (
        <div className={styles.input1}>
          <div className={styles.lastName}>{lastName}</div>
          <input
            className={styles.typedefault1}
            type="text"
            style={typeDefault1Style}
            name="lastName"
            onChange={onChange} // Utilisation de onChange
          />
        </div>
      )}
      {email && (
        <div className={styles.input}>
          <div className={styles.email}>{email}</div>
          <input
            className={styles.typedefault}
            type="email"
            style={typeDefaultStyle}
            name="email"
            onChange={onChange} // Utilisation de onChange
          />
        </div>
      )}
      {phoneNumber && (
        <div className={styles.input1}>
          <div className={styles.phoneNumber}>{phoneNumber}</div>
          <input
            className={styles.typedefault1}
            type="tel"
            style={typeDefault1Style}
            name="phoneNumber"
            onChange={onChange} // Utilisation de onChange
          />
        </div>
      )}
    </div>
  );
};

export default Inputs;
