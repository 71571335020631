import { FunctionComponent, ChangeEvent } from "react";
import styles from "./Input1.module.css";

type Input1Type = {
  messageTextArea: string;
  footercontent: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

const Input1: FunctionComponent<Input1Type> = ({
  messageTextArea,
  footercontent,
  onChange,
}) => {
  return (
    <div className={styles.input}>
      <div className={styles.message}>Message</div>
      <div className={styles.textArea}>
        <textarea
          name="message"
          value={messageTextArea}
          onChange={onChange}
          className={styles.typeYourMessage}
          placeholder={footercontent}
        />
      </div>
    </div>
  );
};

export default Input1;
