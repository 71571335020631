import React, { createContext, useState, useContext, ReactNode } from 'react';

interface Message {
  sender: 'user' | 'bot';
  message: string;
}

interface ChatContextType {
  conversation: Message[];
  addMessage: (msg: Message) => void;
}

const ChatContext = createContext<ChatContextType | null>(null);

export const useChat = () => useContext(ChatContext)!;

interface ChatProviderProps {
  children: ReactNode; // Define the type for 'children' prop
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [conversation, setConversation] = useState<Message[]>([]);

  const addMessage = (msg: Message) => {
    setConversation(prevConversation => [...prevConversation, msg]);
  };

  return (
    <ChatContext.Provider value={{ conversation, addMessage }}>
      {children}
    </ChatContext.Provider>
  );
};
