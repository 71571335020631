import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import TimelineItem from "./TimelineItem";
import styles from "./Layout2.module.css";

const Layout2: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onStyleLinkSmallFalseDarkClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  return (
    <div className={styles.layout121}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.content1}>
            <div className={styles.subheading}>Simplifié</div>
            <h1 className={styles.heading}>
            Nos Services : Un Guide Étape par Étape
            </h1>
          </div>
          <div className={styles.actions}>
            <button
              className={styles.stylesecondarySmallfalse}
              onClick={onStyleSecondarySmallFalseClick}
            >
              <div className={styles.button}>Commencer</div>
            </button>
            <div
              className={styles.stylelinkSmallfalseDark}
              onClick={onStyleLinkSmallFalseDarkClick}
            >
              <div className={styles.button1}>En savoir plus</div>
              <div className={styles.vectorsWrapper}>
                <img
                  className={styles.iconChevronRight}
                  alt=""
                  src="/icon--chevronright.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.timeline}>
          <TimelineItem
            heading="Consultation Initiale"
            text="Planifiez une consultation pour discuter de vos besoins et objectifs."
          />
          <TimelineItem
            heading="Sélection de Service"
            text="Choisissez parmi notre gamme de services en IA et en science des données."
          />
          <TimelineItem
            heading="Mise en Œuvre"
            text="Notre équipe travaillera avec vous pour mettre en œuvre les services choisis."
          />
          <div className={styles.timelineItem}>
            <div className={styles.content2}>
              <img
                className={styles.iconRelume}
                alt=""
                src="/icon--relume-7@2x.png"
              />
            </div>
            <div className={styles.content3}>
              <b className={styles.heading1}>Suivi & Optimisation</b>
              <div className={styles.divider}>
                Nous surveillons et optimisons continuellement les solutions mises en œuvre.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout2;
