import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Actions1 from "./Actions1";
import styles from "./Header.module.css";

const Header: FunctionComponent = () => {
  const navigate = useNavigate();
  const [displayedText, setDisplayedText] = useState("");
  const fullText = "Transformez votre entreprise avec des chatbots pilotés par l'IA. ";

  const onStylePrimarySmallFalseDaClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setDisplayedText((prev) => prev + fullText.charAt(index));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 50); // Vitesse de l'animation (100 ms par lettre)

    return () => clearInterval(interval); // Nettoyage de l'intervalle à la désactivation du composant
  }, [fullText]);

  return (
    <div className={styles.header15}>
      <div className={styles.content}>
        <div className={styles.column}>
          <h1 className={styles.mediumLengthHero}>
            {displayedText}
            <span className={styles.blinkingCursor}></span>
          </h1>
        </div>
        <div className={styles.column1}>
          <div className={styles.loremIpsumDolor}>
            Nos services de chatbot pilotés par l'IA offrent une intégration
            transparente et des expériences client exceptionnelles. Contactez-nous
            pour une démonstration dès aujourd'hui !
          </div>
          <Actions1
            button="Démo"
            propOverflow="unset"
            propPadding="var(--padding-xs) var(--padding-5xl)"
            onStylePrimarySmallFalseDaClick={onStylePrimarySmallFalseDaClick}
          />
        </div>
      </div>
      <img
        className={styles.placeholderImageIcon}
        loading="eager"
        alt=""
        src="/placeholder-image18@2x.png"
      />
    </div>
  );
};

export default Header;
