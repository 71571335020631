import { FunctionComponent, useState, useMemo, useEffect, CSSProperties } from "react";
import styles from "./Navbar1.module.css";

type Navbar1Type = {
  contentBackground?: CSSProperties["background"];
  className?: string; // classe dynamique pour gérer la visibilité de la navbar
  onColumnImageClick?: () => void;
  onLinkOneTextClick?: () => void;
  onLinkTwoTextClick?: () => void;
  onLinkAltTextClick?: () => void;
  onLinkThreeTextClick?: () => void;
  onLinkBlogTextClick?: () => void;
  onActionsContainerClick?: () => void;
};

const Navbar1: FunctionComponent<Navbar1Type> = ({
  className, // Classe CSS dynamique pour gérer la visibilité
  contentBackground,
  onColumnImageClick,
  onLinkOneTextClick,
  onLinkTwoTextClick,
  onLinkAltTextClick,
  onLinkThreeTextClick,
  onLinkBlogTextClick,
  onActionsContainerClick,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1020);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Style de la navbar basé sur le background passé en props
  const navbar11Style: CSSProperties = useMemo(() => {
    return {
      background: contentBackground,
    };
  }, [contentBackground]);

  return (
    <header className={`${styles.navbar11} ${className}`} style={navbar11Style}>
      <div className={styles.content}>
        <img
          className={styles.columnIcon}
          loading="eager"
          alt="Logo"
          src="/column5@2x.png"
          onClick={onColumnImageClick}
        />

        {isMobile ? (
          // Bouton pour ouvrir/fermer le menu mobile
          <div className={styles.mobileMenuIcon} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            ☰
          </div>
        ) : (
          <div className={styles.column}>
            <div className={styles.column1}>
              <div className={styles.linkOne} onClick={onLinkOneTextClick}>
                Services de Coaching
              </div>
              <div className={styles.linkTwo} onClick={onLinkTwoTextClick}>
                Solutions Chatbot
              </div>
              <div className={styles.linkAlt} onClick={onLinkAltTextClick}>
                À Propos
              </div>
              <div className={styles.linkThree} onClick={onLinkThreeTextClick}>
                Autres Services
              </div>
              <div className={styles.linkBlog} onClick={onLinkBlogTextClick}>
                Blogue
              </div>
            </div>
            <div className={styles.actions} onClick={onActionsContainerClick}>
              <div className={styles.styleprimarySmalltrueDar}>
                <div className={styles.button}>CONTACT</div>
              </div>
            </div>
          </div>
        )}

        {isMobileMenuOpen && (
          <div className={styles.mobileMenu}>
            <div className={styles.mobileLink} onClick={onLinkOneTextClick}>
              Services de Coaching
            </div>
            <div className={styles.mobileLink} onClick={onLinkTwoTextClick}>
              Solutions Chatbot
            </div>
            <div className={styles.mobileLink} onClick={onLinkAltTextClick}>
              À Propos
            </div>
            <div className={styles.mobileLink} onClick={onLinkThreeTextClick}>
              Autres Services
            </div>
            <div className={styles.mobileLink} onClick={onLinkBlogTextClick}>
              Blogue
            </div>
            <div className={styles.mobileLink} onClick={onActionsContainerClick}>
              CONTACT
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar1;
