import React, { FunctionComponent, useState, ChangeEvent } from "react";
import Inputs from "./Inputs";
import Column3 from "./Column3";
import Input1 from "./Input1";
import styles from "./Form1.module.css";

interface Form1Props {
  onFormSubmit: () => void;
}

const Form1: FunctionComponent<Form1Props> = ({ onFormSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    topic: '',
    message: '',
    acceptTerms: false,
    description: '',
    expertise: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Pour gérer l'erreur

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement;
    const { name, value } = target;
    const checked = target.checked;

    setFormData({
      ...formData,
      [name]: target.type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!formData.acceptTerms) {
      setErrorMessage("Vous devez accepter les termes avant de soumettre le formulaire.");
      return; // Ne pas soumettre le formulaire
    }

    setErrorMessage(null); // Réinitialiser le message d'erreur si le checkbox est coché
    setFormSubmitted(true);
    onFormSubmit();  // Appeler la fonction de rappel
    window.scrollTo({ top: 0, behavior: 'smooth' });  // Faire défiler vers le haut de la page

    try {
      const response = await fetch('https://contact-form-backend-service-732usxrs2q-uc.a.run.app/api/form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Logique supplémentaire si nécessaire
      }
    } catch (error) {
      console.error('Error:', error);
      // Pas de changement de comportement, toujours afficher le même message
    }
  };

  const handleRetry = () => {
    setFormSubmitted(false);
  };

  if (formSubmitted) {
    return (
      <div className={`${styles.messageContainer} ${styles.successAnimation}`}>
        <div className={styles.messageContent}>
          <p className={styles.thankYouMessage}><strong>Merci, nous vous avons envoyé un message de confirmation par courriel.</strong></p>
          <p className={styles.retryMessage}>
            Si vous n'avez pas reçu de courriel, veuillez <span className={styles.retryLink} onClick={handleRetry}>réessayer</span>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Inputs firstName="Prénom" lastName="Nom de famille" onChange={handleChange} />
      <Inputs email="Courriel" phoneNumber="Téléphone" onChange={handleChange} />
      <div className={styles.input}>
        <div className={styles.chooseATopic}>Sujet</div>
        <select name="topic" value={formData.topic} onChange={handleChange}>
          <option value="">Choisir...</option>
          <option value="Coaching">Coaching</option>
          <option value="Chatbot">Chatbot</option>
          <option value="Solutions personnalisé">Solutions personnalisé</option>
          <option value="développement web">développement web</option>
          <option value="Autre">Autre</option>
        </select>
      </div>
      <div className={styles.radios}>
        <div className={styles.whichBestDescribes}>Ce qui vous décrit le mieux?</div>
        <div className={styles.content}>
          <Column3
            radioName="description"
            label="PME"
            radioName1="description"
            label1="Travailleur autonome"
            radioName2="description"
            label2="Employé"
            onChange={handleChange}
          />
          <Column3
            radioName="expertise"
            label="Expert"
            radioName1="expertise"
            label1="Intermédiaire"
            radioName2="expertise"
            label2="Débutant"
            onChange={handleChange}
          />
        </div>
      </div>
      <Input1
        messageTextArea={formData.message}
        footercontent="Parlez-nous de votre projet ou poser vos questions ici..."
        onChange={handleChange}
      />
      <div className={styles.selectedfalse}>
        <input
          className={styles.checkbox}
          type="checkbox"
          name="acceptTerms"
          checked={formData.acceptTerms}
          onChange={handleChange}
        />
        <div className={styles.label}>
          J'accepte les <a href="/terms-of-use" target="_blank" className={styles.link}>termes et conditions</a> {/* Lien vers la page Terms of Use */}
        </div>
      </div>

      {/* Afficher un message d'erreur si les termes ne sont pas acceptés */}
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

      <button className={styles.styleprimarySmallfalseDa} type="submit">
        <div className={styles.button}>Envoyer</div>
      </button>
    </form>
  );
};

export default Form1;
