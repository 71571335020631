import { FunctionComponent } from "react";
import styles from "./CTA1.module.css";

const CTA1: FunctionComponent = () => {
  return (
    <section className={styles.cta26}>
      <div className={styles.content}>
        <div className={styles.content1}>
          <h1 className={styles.heading}>
            Services Experts en ML et Science des Données
          </h1>
          <div className={styles.fAQ}>
            Planifiez une consultation pour en savoir plus sur nos services
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.form}>
            <div className={styles.typedefault}>
              <input
                className={styles.placeholder}
                placeholder="Entrez votre email ici"
                type="text"
              />
            </div>
            <div className={styles.styleprimarySmallfalseDa}>
              <div className={styles.button}>Commencer</div>
            </div>
          </div>
          <div className={styles.byClickingSign}>
            En cliquant sur Commencer, vous acceptez nos Conditions Générales.
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA1;
