import { useState } from "react";
import styles from "./CTA.module.css";

const CTA = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleSubscribe = async () => {
    if (!email) {
      setMessageType('error');
      setMessage('Veuillez entrer un email valide.');
      return;
    }

    try {
      const response = await fetch('https://newsletter-backend-service-732usxrs2q-uc.a.run.app/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessageType('success');
        setMessage('Email enregistré avec succès !');
        setEmail('');
      } else {
        const errorMessage = await response.text();
        setMessageType('error');
        setMessage(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessageType('error');
      setMessage('Erreur lors de l\'enregistrement de l\'email.');
    }
  };

  return (
    <div className={styles.cta26}>
      <div className={styles.content}>
        <div className={styles.content1}>
          <h1 className={styles.heading}>Restez à jour sur l'IA</h1>
          <div className={styles.icon}>
            Abonnez-vous à notre newsletter pour les dernières mises à jour, conseils et
            perspectives sur l'IA et la science des données.
          </div>
        </div>
        <div className={styles.actions}>
          <div className={styles.form}>
            <div className={styles.typedefault}>
              <input
                className={styles.placeholder}
                placeholder="Votre Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button className={styles.styleprimarySmallfalseDa} onClick={handleSubscribe}>
              <div className={styles.button}>S'abonner</div>
            </button>
          </div>
          <div className={styles.byClickingSign}>
            En vous abonnant, vous acceptez nos Conditions Générales.
          </div>
          {message && (
            <div className={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CTA;
