import React, { useState, useEffect, FormEvent, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "./Arty.module.css";



type Message = {
  role: 'user' | 'bot';
  content: string;
};

const messagesOptions = [
  "Hey! C'est moi ça!",
  "C'est ma page!",
  "Mais qui est ce charmant robot?",
  "Ne suis-je pas MAJESTUEUX?!",
];

function Arty() {
  const [userInput, setUserInput] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([
    { role: 'bot', content: 'Salut je m’appelle Arty, l\'assistant virtuel d\'alpaga comment puis-je t\'aider aujourd\'hui?' }
  ]);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isMinimized, setIsMinimized] = useState<boolean>(true);
  const [specialMessageShown, setSpecialMessageShown] = useState<boolean>(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMinimized(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const checkScroll = () => {
      if (specialMessageShown) return;

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (location.pathname === "/chatbots" && scrollPercentage > 30) {
        const randomMessage = messagesOptions[Math.floor(Math.random() * messagesOptions.length)];
        setMessages(prev => [...prev, { role: 'bot', content: randomMessage }]);
        setSpecialMessageShown(true);
        if (isMinimized) setIsMinimized(false);
      }
    };

    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, [location.pathname, specialMessageShown, isMinimized]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!userInput.trim()) return;
    setMessages(prev => [...prev, { role: 'user', content: userInput }]);
    setUserInput('');
    setIsTyping(true);
  
    try {
      const response = await fetch('https://assistant-backend-service-732usxrs2q-uc.a.run.app/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: userInput, page: location.pathname }), // Inclure la page ici
      });
  
      const data = await response.json();
      setIsTyping(false);
      setMessages(prev => [...prev, { role: 'bot', content: data[0].content }]);
    } catch (error) {
      console.error('Failed to send message:', error);
      setIsTyping(false);
      setMessages(prev => [...prev, { role: 'bot', content: 'Désolé, une erreur s\'est produite. Veuillez réessayer.' }]);
    }
  };
    
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute('href');
    if (target) {
      navigate(target);
    }
  };

  useEffect(() => {
    const links = document.querySelectorAll('.internal-link');
    const handleLinkClickWrapper = (event: Event) => handleLinkClick(event as unknown as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
    links.forEach(link => {
      link.addEventListener('click', handleLinkClickWrapper as EventListener);
    });
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleLinkClickWrapper as EventListener);
      });
    };
  }, []); // Exécuter une seule fois au montage
  
  const createMarkup = (htmlContent: string) => {
    return { __html: htmlContent };
  };

  return (
    <div className={styles['arty-container']}>
      {isMinimized ? (
        <div className={styles['minimized-icon']} onClick={() => setIsMinimized(false)} />
      ) : (
        <>
          <div className={styles['minimize-button']} onClick={() => setIsMinimized(true)}>x</div>
          <form onSubmit={handleSendMessage} className={styles['input-area']}>
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Aa"
              className={styles['input-field']}
            />
            <button type="submit" className={styles['send-button']}>Send</button>
          </form>
          <div className={styles['conversation']}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`${styles['message']} ${msg.role === 'user' ? styles['user-message'] : styles['bot-message']}`}
                dangerouslySetInnerHTML={createMarkup(msg.content)}  // This allows rendering HTML content
              />
            ))}
            {isTyping && <div className={`${styles['message']} ${styles['bot-message']}`}>...</div>}
            <div ref={messagesEndRef} />
          </div>
        </>
      )}
    </div>
  );
}

export default Arty;
