import { FunctionComponent, useMemo, useState, type CSSProperties } from "react";
import { Link } from "react-router-dom";
import styles from "./Footer3.module.css";

type Footer3Type = {
  iconInstagram?: string;
  propBackgroundImage?: CSSProperties["backgroundImage"];
  onColumnOneTextClick?: () => void;
  onLinkOneText2Click?: () => void;
  onLinkTwoText2Click?: () => void;
  onLinkEightTextClick?: () => void;
  className?: string;
};

const Footer3: FunctionComponent<Footer3Type> = ({
  iconInstagram,
  propBackgroundImage,
  onColumnOneTextClick,
  onLinkOneText2Click,
  onLinkTwoText2Click,
  onLinkEightTextClick,
  className,
}) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleSubscribe = async () => {
    if (!email) {
      setMessageType('error');
      setMessage('Veuillez entrer un email valide.');
      return;
    }

    try {
      const response = await fetch('https://newsletter-backend-service-732usxrs2q-uc.a.run.app/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessageType('success');
        setMessage('Email enregistré avec succès !');
        setEmail('');
      } else {
        const errorMessage = await response.text();
        setMessageType('error');
        setMessage(errorMessage);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessageType('error');
      setMessage('Erreur lors de l\'enregistrement de l\'email.');
    }
  };

  const column1Style: CSSProperties = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage,
    };
  }, [propBackgroundImage]);

  return (
    <footer className={`${styles.footer2} ${className ? className : ""}`}>
      <div className={styles.content}>
        <div className={styles.links}>
          <Link to="/">
            <div className={styles.column} style={column1Style}>
              <img className={styles.colorDark} alt="" src="/color--dark.svg" />
            </div>
          </Link>
          <div className={styles.column1}>
            <div className={styles.columnOne} onClick={onColumnOneTextClick}>
              À propos de nous
            </div>
            <div className={styles.footerLinks}>
              <div className={styles.link}>
                <div className={styles.linkOne} onClick={onLinkOneText2Click}>
                  Services
                </div>
              </div>
              <div className={styles.link1}>
                <div className={styles.linkTwo} onClick={onLinkTwoText2Click}>
                  Contactez-nous
                </div>
              </div>
              <div className={styles.link2}>
                <Link to="/#faq" className={styles.customLink}>
                  <div className={styles.linkThree}>FAQ</div>
                </Link>
              </div>
              <div className={styles.link3}>
                <Link to="/blog" className={styles.customLink}>
                  <div className={styles.linkFour}>Blog</div>
                </Link>
              </div>
              <div className={styles.link4}>
                <div className={styles.linkFive}>Témoignages</div>
              </div>
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.columnTwo}>Ressources</div>
            <div className={styles.footerLinks1}>
              <div className={styles.link5}>
                <div className={styles.linkSix}>Support</div>
              </div>
              <div className={styles.link6}>
                <Link to="/privacy-policy" className={styles.customLink}> {/* Lien vers la politique de confidentialité */}
                  <div className={styles.linkSeven}>Politique de confidentialité</div>
                </Link>
              </div>
              <div className={styles.link7}>
                <div className={styles.linkEight} onClick={onLinkEightTextClick}>
                  Coaching en IA
                </div>
              </div>
              <div className={styles.link8}>
                <div className={styles.linkNine}>Paramètres des cookies</div>
              </div>
              <div className={styles.link9}>
                <div className={styles.linkTen}>Produit Un</div>
              </div>
            </div>
          </div>
          <div className={styles.column3}>
            <div className={styles.columnThree}>Produit Deux</div>
            <div className={styles.footerLinks2}>
              <div className={styles.link10}>
                <div className={styles.linkEleven}>Produit Trois</div>
              </div>
              <div className={styles.link11}>
                <div className={styles.linkTwelve}>Produit Quatre</div>
              </div>
              <div className={styles.link12}>
                <div className={styles.linkThirteen}>Produit Cinq</div>
              </div>
              <div className={styles.link13}>
                <div className={styles.linkFourteen}>Produit Six</div>
              </div>
              <div className={styles.link14}>
                <div className={styles.linkFifteen}>Produit Sept</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.newslatter}>
          <div className={styles.subscribeParent}>
            <div className={styles.subscribe}>Abonnez-vous</div>
            <div className={styles.joinOurNewsletter}>
              Rejoignez notre communauté pour recevoir des mises à jour et des offres exclusives.
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.form}>
              <div className={styles.typedefault}>
                <input
                  className={styles.placeholder}
                  placeholder="Entrez votre email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button className={styles.stylesecondarySmallfalse} onClick={handleSubscribe}>
                <div className={styles.button}>S'abonner</div>
              </button>
            </div>
            {message && (
              <div className={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
                {message}
              </div>
            )}
            <div className={styles.bySubscribingYou}>
              En vous abonnant, vous acceptez notre <Link to="/privacy-policy" className={styles.customLink}>Politique de confidentialité</Link> et consentez à recevoir des mises à jour de notre société.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.credits}>
        <div className={styles.divider} />
        <div className={styles.row}>
          <div className={styles.credits1}>
            <div className={styles.relumeAllRights}>
              © 2024 Alpaga Inc. Tous droits réservés.
            </div>
            <div className={styles.footerLinks3}>
              <Link to="/privacy-policy" className={styles.customLink}>
                <div className={styles.privacyPolicy}>Politique de confidentialité</div>
              </Link>
              <Link to="/terms-of-use" className={styles.customLink}> {/* Lien vers les conditions d'utilisation */}
                <div className={styles.termsOfService}>Conditions d'utilisation</div>
              </Link>
              <div className={styles.cookiesSettings}>Paramètres des cookies</div>
            </div>
          </div>
          <div className={styles.socialLinks}>
            <img
              className={styles.iconInstagram}
              loading="eager"
              alt="Instagram"
              src={iconInstagram}
            />
            <img
              className={styles.iconLinkedin}
              alt="LinkedIn"
              src="/icon--linkedin.svg"
            />
            <img
              className={styles.iconYoutube}
              loading="eager"
              alt="YouTube"
              src="/icon--youtube.svg"
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer3;
