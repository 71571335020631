import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import Layout9LeftImage from "../components/Layout9LeftImage"; // Import du nouveau composant
import Layout9 from "../components/Layout9";
import Layout8 from "../components/Layout8";
import Layout7 from "../components/Layout7";
import CTA1 from "../components/CTA1";
import FAQ3 from "../components/FAQ3";
import Footer3 from "../components/Footer3";
import styles from "./OtherServices.module.css";

const OtherServices: FunctionComponent = () => {
  const navigate = useNavigate();

  const onColumnImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkOneTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  const onLinkTwoTextClick = useCallback(() => {
    navigate("/chatbots");
  }, [navigate]);

  const onLinkAltTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkThreeTextClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkBlogTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onActionsContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onColumnOneTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkOneText2Click = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkTwoText2Click = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onLinkEightTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  return (
    <div className={styles.otherServices}>
      <Navbar1
        contentBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 228, 195, 0.9))"
        onColumnImageClick={onColumnImageClick}
        onLinkOneTextClick={onLinkOneTextClick}
        onLinkTwoTextClick={onLinkTwoTextClick}
        onLinkAltTextClick={onLinkAltTextClick}
        onLinkThreeTextClick={onLinkThreeTextClick}
        onLinkBlogTextClick={onLinkBlogTextClick} // Propriété pour le blog
        onActionsContainerClick={onActionsContainerClick}
      />

      {/* Nouveau composant avec l'image à gauche et du Lorem Ipsum */}
      <Layout9LeftImage
        heading="Des solutions sur mesure pour automatiser vos opérations"
        contentFrame="Imaginez une solution d'IA qui automatise vos processus quotidiens : un système qui génère des soumissions instantanément à partir de vos critères, des prédictions précises pour votre gestion des stocks, ou encore un assistant intelligent connecté en temps réel à votre CRM qui personnalise chaque interaction client avec leur historique complet. Nos solutions d'automatisation intelligente transforment vos tâches répétitives en processus fluides et efficaces, libérant votre équipe pour des activités à plus forte valeur ajoutée."
        iconRelume="/icon--relume.svg"
        subheadingOne="Pourquoi choisir Alpaga ?"
        formInputField="Nous créons des solutions uniques adaptées à vos défis. Que ce soit un système d'automatisation des flux de travail ou une analyse prédictive pour anticiper les tendances du marché, les possibilités sont infinies."
        iconRelume2="/icon--relume.svg"
        subheadingTwo="Des experts à votre service"
        creditsDivider="Faites confiance à notre équipe pour imaginer des solutions qui transforment vos idées en réalité, et vous aident à atteindre de nouveaux sommets de performance."
        placeholderImage="/custom_app.png" // Assurez-vous que cette image existe
        iconRelume1={false}
        iconRelume3={false}
      />

      <Layout9
        heading="Des données propres: la clé d'analyses qui font la différence"
        contentFrame="Le nettoyage des données est un service crucial qui garantit l'intégrité et l'exactitude de vos données. Notre expertise en ETL (Extraction, Transformation, Chargement) et en structuration transforme vos informations brutes en une base solide et cohérente. Nous détectons et corrigeons les anomalies, harmonisons vos données et éliminons les redondances pour vous garantir des analyses justes qui inspirent confiance. Avec des données épurées, chacune de vos décisions s'appuie sur des fondations solides."
        iconRelume="/icon--relume.svg"
        subheadingOne="Pourquoi nous choisir"
        formInputField="Notre service de nettoyage des données garantit des données de haute qualité pour une prise de décision éclairée"
        iconRelume2="/icon--relume.svg"
        subheadingTwo="Solutions d'experts"
        creditsDivider="Faites confiance à notre équipe pour nettoyer vos données et en exploiter tout le potentiel."
        placeholderImage="/placeholder-image12@2x.png"
        iconRelume1={false}
        iconRelume3={false}
      />

      {/* Placement de Layout8 juste au-dessus de la section des tableaux de bord */}
      <Layout8 />

      {/* Section sur les tableaux de bord */}
      <section className={styles.layout194}>
        <div className={styles.container}>
          <img
            className={styles.placeholderImageIcon}
            alt=""
            src="/placeholder-image13@2x.png"
          />
          <div className={styles.content}>
            <h1 className={styles.heading}>
            Donnez du sens à vos données pour des décisions d'affaires stratégiques
            </h1>
            <div className={styles.iconInstagram}>
            Nous vous accompagnons dans l'exploitation de votre plus grande richesse : vos données d'entreprise. Notre équipe d'experts vous guide à chaque étape, de l'organisation de vos données jusqu'à leur transformation en leviers de croissance concrets. Grâce à nos tableaux de bord sur mesure et nos analyses approfondies, vous découvrirez le plein potentiel de votre patrimoine numérique pour prendre des décisions gagnantes.            </div>
          </div>
        </div>
      </section>

      {/* Offre de service - Recrutement spécialisé */}
      <Layout9
        heading="Trouvez les experts IA adaptés à vos projets"
        contentFrame="Vous voulez assembler une équipe à l'interne ? Nos services de recrutement spécialisé vous aident à identifier, évaluer et recruter les meilleurs talents en intelligence artificielle pour vos projets. Nous vous guidons à chaque étape, de la définition du poste à l'intégration du candidat, afin d'assurer un recrutement parfaitement aligné avec vos objectifs."
        iconRelume="/icon--recruitment.svg"
        subheadingOne="Identification des talents IA"
        formInputField="Nous recherchons activement les professionnels en IA les plus qualifiés, même ceux qui ne sont pas en recherche active, afin de vous proposer des profils rares et adaptés à vos besoins spécifiques."
        iconRelume2="/icon--talent.svg"
        subheadingTwo="Conseils et accompagnement sur mesure"
        creditsDivider="Notre expertise en recrutement vous garantit des solutions personnalisées, optimisant chaque étape pour sélectionner des candidats qui s'intégreront efficacement dans votre équipe."
        placeholderImage="/employe.png"
        iconRelume1={true}
        iconRelume3={false}
      />

      <Layout7 />
      <CTA1 />
      <FAQ3 />
      <Footer3
        iconInstagram="/icon--instagram.svg"
        propBackgroundImage="url('/column4@3x.png')"
        onColumnOneTextClick={onColumnOneTextClick}
        onLinkOneText2Click={onLinkOneText2Click}
        onLinkTwoText2Click={onLinkTwoText2Click}
        onLinkEightTextClick={onLinkEightTextClick}
      />
    </div>
  );
};

export default OtherServices;
