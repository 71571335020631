import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import Layout9 from "../components/Layout9";
import Pricing from "../components/Pricing";
import Testimonial from "../components/Testimonial";

import Footer3 from "../components/Footer3";
import styles from "./Mentoring.module.css";

const Mentoring: FunctionComponent = () => {
  const navigate = useNavigate();

  const onColumnImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkOneTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  const onLinkTwoTextClick = useCallback(() => {
    navigate("/chatbots");
  }, [navigate]);

  const onLinkAltTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkThreeTextClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkBlogTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onActionsContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={styles.mentoring}>
      <Navbar1
        contentBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(201, 193, 236, 0.9))"
        onColumnImageClick={onColumnImageClick}
        onLinkOneTextClick={onLinkOneTextClick}
        onLinkTwoTextClick={onLinkTwoTextClick}
        onLinkAltTextClick={onLinkAltTextClick}
        onLinkThreeTextClick={onLinkThreeTextClick}
        onLinkBlogTextClick={onLinkBlogTextClick} // Ajout de la propriété pour le blog
        onActionsContainerClick={onActionsContainerClick}
      />
      <Layout9
        heading="Sessions de coaching adaptées à votre rythme et à votre niveau"
        contentFrame="Nos sessions de coaching offrent des conseils personnalisés et un soutien en intelligence artificielle générative. Bénéficiez de l'expertise de nos mentors pour accélérer votre parcours d'apprentissage."
        subheadingOne="Au-delà de ChatGPT"
        formInputField="Nos mentors ont une vaste expérience en intelligence artificielle générative et sont prêts à vous guider dans la découverte des différents outils disponibles."
        iconRelume2="icon--relume-7@2x.png"
        subheadingTwo="Personnalisé"
        creditsDivider="Approfondissez vos compétences en intelligence artificielle générative avec des ressources et des exemples adaptés à votre domaine."
        placeholderImage="/placeholder-image17@2x.png"
        iconRelume1
        iconRelume3
        propHeight="144px"
      />
      <Pricing />
      <Testimonial />

      <Footer3 
      iconInstagram="/icon--instagram.svg"
      />
    </div>
  );
};

export default Mentoring;
