import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Layout4.module.css";

const Layout4: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={styles.layout22}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sectionTitle}>
            <img
              className={styles.iconRelume}
              loading="eager"
              alt=""
              src="/icon--relume6.svg"
            />
            <div className={styles.content1}>
              <h1 className={styles.heading}>
             Optimisez votre entreprise avec des solutions IA personnalisées
              </h1>
              <div className={styles.text}>
              Nous aidons les jeunes entreprises et les PME du Québec à intégrer l'IA générative dans leurs opérations. Nos services comprennent la mise en place de solutions d'automatisation sur mesure, le développement de assistants virtuels intelligents, ainsi que le coaching et la consultation en IA générative. Grâce à notre expertise, vous pourrez maîtriser et utiliser divers outils d'IA pour transformer votre entreprise et optimiser vos processus.
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <button
              className={styles.stylesecondarySmallfalse}
              onClick={onStyleSecondarySmallFalseClick}
            >
              <div className={styles.button}>CONTACTEZ-NOUS MAINTENANT</div>
            </button>
            <div className={styles.stylelinkSmallfalseDark}>
              <div className={styles.button1}>S'inscrire</div>
              <img
                className={styles.iconChevronRight}
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
        <img
          className={styles.placeholderImageIcon}
          loading="eager"
          alt=""
          src="/placeholder-image@2x.png"
        />
      </div>
    </div>
  );
};

export default Layout4;
