import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Card1.module.css";

type Card1Type = {
  placeholderImage?: string;
  name1?: string;
  jobTitle?: string;
  text?: string;
  iconLinkedIn?: string;
  iconX?: string;
  linkedInUrl?: string; // Ajout du lien LinkedIn

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propBorderRadius?: CSSProperties["borderRadius"];
  propHeight?: CSSProperties["height"];
  propHeight1?: CSSProperties["height"];
  propHeight2?: CSSProperties["height"];
};

const Card1: FunctionComponent<Card1Type> = ({
  placeholderImage,
  name1,
  jobTitle,
  text,
  iconLinkedIn,
  iconX,
  linkedInUrl, // Ajout du lien LinkedIn
  propFlex,
  propBorderRadius,
  propHeight,
  propHeight1,
  propHeight2,
}) => {
  const cardStyle: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
    };
  }, [propFlex]);

  const placeholderImageIcon3Style: CSSProperties = useMemo(() => {
    return {
      borderRadius: propBorderRadius,
    };
  }, [propBorderRadius]);

  const name3Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  const jobTitle3Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight1,
    };
  }, [propHeight1]);

  const text2Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight2,
    };
  }, [propHeight2]);

  return (
    <div className={styles.card} style={cardStyle}>
      <img
        className={styles.placeholderImageIcon}
        alt=""
        src={placeholderImage}
        style={placeholderImageIcon3Style}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.name} style={name3Style}>
            {name1}
          </div>
          <div className={styles.jobTitle} style={jobTitle3Style}>
            {jobTitle}
          </div>
        </div>
        <div className={styles.text} style={text2Style}>
          {text}
        </div>
      </div>
      <div className={styles.socialIcons}>
        {/* Ajout du lien LinkedIn autour de l'icône */}
        {linkedInUrl && (
          <a href={linkedInUrl} target="_blank" rel="noopener noreferrer">
            <img
              className={styles.iconLinkedin}
              loading="eager"
              alt="LinkedIn"
              src={iconLinkedIn}
            />
          </a>
        )}
        {/* Afficher l'icône X seulement si ce n'est pas Tommy */}
        {name1 !== "Tommy Delorme" && (
          <img className={styles.iconX} loading="eager" alt="X" src={iconX} />
        )}
      </div>
    </div>
  );
};

export default Card1;
