import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Mentoring from "./pages/Mentoring";
import Chatbots from "./pages/Chatbots";
import OtherServices from "./pages/OtherServices";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import BlogArticleDetail from "./components/BlogArticleDetail";
import Arty from "./components/Arty";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import './global.css';

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Accueil";
        metaDescription = "Bienvenue sur notre page d'accueil";
        break;
      case "/mentoring":
        title = "Mentoring Services";
        metaDescription = "Explorez nos services de mentorat";
        break;
      case "/chatbots":
        title = "Solutions de Chatbots";
        metaDescription = "Découvrez nos solutions de robots conversationnels";
        break;
      case "/other-services":
        title = "Autres Services";
        metaDescription = "Consultez nos autres services";
        break;
      case "/about-us":
        title = "À propos de nous";
        metaDescription = "En savoir plus sur notre équipe et mission";
        break;
      case "/contact":
        title = "Contactez-nous";
        metaDescription = "Contactez-nous pour plus d'informations";
        break;
      case "/blog":
        title = "Blog";
        metaDescription = "Lisez nos derniers articles";
        break;
      case `/blog/${pathname.split('/').pop()}`:
        title = "Article de Blog";
        metaDescription = "Lisez l'article complet";
        break;
      case "/terms-of-use":
        title = "Conditions d'utilisation";
        metaDescription = "Consultez nos termes et conditions d'utilisation.";
        break;
      case "/privacy-policy":
        title = "Politique de confidentialité";
        metaDescription = "Découvrez comment nous protégeons vos données personnelles.";
        break;
      default:
        title = "Alpaga - Votre partenaire en IA";
        metaDescription = "Bienvenue chez Alpaga, votre partenaire en intelligence artificielle.";
        break;
    }

    document.title = title;

    // Mise à jour ou création de la balise meta description
    let metaDescriptionTag = document.querySelector('head > meta[name="description"]') as HTMLMetaElement | null;
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    } else {
      const newMetaDescriptionTag = document.createElement("meta");
      newMetaDescriptionTag.name = "description";
      newMetaDescriptionTag.content = metaDescription;
      document.head.appendChild(newMetaDescriptionTag);
    }

    // Mise à jour ou création de la balise canonique
    const canonicalUrl = `https://alpaga.ai${pathname}`;
    let linkCanonical = document.querySelector('link[rel="canonical"]') as HTMLLinkElement | null;
    if (linkCanonical) {
      linkCanonical.href = canonicalUrl;
    } else {
      linkCanonical = document.createElement("link");
      linkCanonical.rel = "canonical";
      linkCanonical.href = canonicalUrl;
      document.head.appendChild(linkCanonical);
    }
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mentoring" element={<Mentoring />} />
        <Route path="/chatbots" element={<Chatbots />} />
        <Route path="/other-services" element={<OtherServices />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        {/* Mise à jour de la route de détail de blog avec :slug et :id */}
        <Route path="/blog/:slug/:id" element={<BlogArticleDetail />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Arty />
    </>
  );
}

export default App;
