import { FunctionComponent, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FAQ1.module.css"; // Utilise le CSS de FAQ1

const FAQ3: FunctionComponent = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    { question: "Comment fonctionne le coaching ?", answer: "Le coaching fonctionne en vous offrant un soutien personnalisé et des conseils d'experts pour vous aider à maîtriser l'IA et les sciences des données." },
    { question: "Qu'est-ce que les chatbots ?", answer: "Les chatbots sont des programmes informatiques qui simulent des conversations humaines pour fournir des informations ou des services automatisés." },
    { question: "Qu'est-ce que les tableaux de bord ?", answer: "Les tableaux de bord sont des outils visuels qui affichent des données importantes de manière claire et concise pour faciliter la prise de décisions." },
    { question: "Comment fonctionne le nettoyage des données ?", answer: "Le nettoyage des données consiste à identifier et corriger ou supprimer les données incorrectes, corrompues, mal formatées ou dupliquées." },
    { question: "Qu'est-ce que l'analyse des données ?", answer: "L'analyse des données est le processus d'examen, de nettoyage et de modélisation des données dans le but de découvrir des informations utiles, de tirer des conclusions et de soutenir la prise de décision." }
  ];

  return (
    <section className={styles.faq2}>
      <div className={styles.sectionTitleWrapper}>
        <div className={styles.sectionTitle}>
          <h1 className={styles.faqs}>FAQs</h1>
        </div>
      </div>
      <div className={styles.accordion}>
        {faqs.map((faq, index) => (
          <div key={index} className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} onClick={() => handleToggle(index)}>
              <b className={styles.question}>{faq.question}</b>
              <img
                className={styles.icon}
                loading="eager"
                alt=""
                src="/icon.svg"
              />
            </div>
            <div
              className={`${styles.answer} ${openIndex === index ? styles.open : ""}`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.contentArea}>
        <div className={styles.content}>
          <div className={styles.content1}>
            <h1 className={styles.stillHaveA}>Vous avez encore des questions ?</h1>
            <div className={styles.text}>Contactez-nous pour plus d'informations.</div>
          </div>
          <button
            className={styles.stylesecondarySmallfalse}
            onClick={onStyleSecondarySmallFalseClick}
          >
            <div className={styles.button}>Contact</div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default FAQ3;
