import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Layout10.module.css";

const Layout10: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  return (
    <section className={styles.layout22}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sectionTitle}>
            <img
              className={styles.iconRelume}
              loading="eager"
              alt=""
              src="/icon--relume6.svg"
            />
            <div className={styles.content1}>
              <h1 className={styles.heading}>
                Rendre l'IA et la science des données accessibles à tous
              </h1>
              <div className={styles.textContent}>
              Alpaga est une entreprise spécialisée dans les services et le conseil en Intelligence Artificielle (IA) et en sciences des données, dédiée à aider les petites et moyennes entreprises (PME) à améliorer leur performance et à accélérer leur transformation numérique. Notre mission est de rendre les technologies de pointe, telles que le machine learning, le deep learning et l'IA générative, accessibles et utilisables par les entreprises de toutes tailles, en leur offrant des solutions sur mesure qui répondent à leurs besoins spécifiques.


              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <button
              className={styles.stylesecondarySmallfalse}
              onClick={onStyleSecondarySmallFalseClick}
            >
              <div className={styles.button}>Plus d'infos</div>
            </button>
            <div className={styles.stylelinkSmallfalseDark}>
              <div className={styles.button1}>Inscrivez-vous</div>
              <img
                className={styles.iconChevronRight}
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
        <img
          className={styles.placeholderImageIcon}
          loading="eager"
          alt=""
          src="/placeholder-image2@2x.png"
        />
      </div>
    </section>
  );
};

export default Layout10;
