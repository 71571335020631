import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Content2 from "./Content2";
import styles from "./Layout5.module.css";

const Layout5: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={styles.layout121}>
      <Content2
        subheading="Simplification"
        heading="Facilitez la Mise en Place d'un Chatbot IA"
        heading1="Processus de Consultation"
        title="Nous commençons par une consultation initiale pour comprendre vos besoins et objectifs."
        heading2="Options de Personnalisation"
        text="Notre équipe adaptera le chatbot à votre marque et à vos exigences."
        heading3="Intégration Transparente"
        text1="Nous assurons une intégration sans faille du chatbot dans vos systèmes existants."
        heading4="Formation et Support"
        privacyPolicyTermsCookies="Nous offrons une formation complète et un support continu pour votre équipe."
        subheading1={false}
        propWidth="unset"
        onStyleSecondarySmallFalseClick={onStyleSecondarySmallFalseClick}
      />
    </div>
  );
};

export default Layout5;
