import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Layout3.module.css";

const Layout3: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  return (
    <div className={styles.layout207}>
      <div className={styles.container}>
        <img
          className={styles.placeholderImageIcon}
          alt=""
          src="/everyone.png"
        />
        <div className={styles.content}>
          <div className={styles.content1}>
            <div className={styles.sectionTitle}>
              <div className={styles.subheading}>Accessible</div>
              <div className={styles.content2}>
                <h1 className={styles.heading}>
                  Rendre les Solutions en IA Accessibles à Tous
                </h1>
                <div className={styles.text}>
                Notre mission est de démystifier l'intelligence artificielle et de la rendre accessible à chaque entreprise québécoise, quelle que soit sa taille ou son niveau d'expertise technique. En tant que pont entre la complexité de l'IA et les besoins concrets des entreprises, nous transformons des technologies sophistiquées en solutions pratiques et abordables. De la formation personnalisée aux outils clés en main, nous vous accompagnons pas à pas pour que l'IA devienne un véritable levier de croissance à votre portée.                </div>
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listItem}>
                <img
                  className={styles.iconRelume}
                  alt=""
                  src="/icon--relume-1.svg"
                />
                <div className={styles.loremIpsumDolor}>
                  Coaching en IA générative
                </div>
              </div>
              <div className={styles.listItem1}>
                <img
                  className={styles.iconRelume1}
                  alt=""
                  src="/icon--relume-1.svg"
                />
                <div className={styles.loremIpsumDolor1}>
                  Développement de chatbot
                </div>
              </div>
              <div className={styles.listItem2}>
                <img
                  className={styles.iconRelume2}
                  alt=""
                  src="/icon--relume-1.svg"
                />
                <div className={styles.loremIpsumDolor2}>
                Évaluation et recommandations stratégique
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <button
              className={styles.stylesecondarySmallfalse}
              onClick={onStyleSecondarySmallFalseClick}
            >
              <div className={styles.button}>En savoir plus</div>
            </button>
            <div className={styles.stylelinkSmallfalseDark}>
              <div className={styles.button1}>S'inscrire</div>
              <img
                className={styles.iconChevronRight}
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout3;
