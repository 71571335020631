import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import du hook de navigation
import Footer3 from "../components/Footer3";
import Navbar1 from "../components/Navbar1";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate(); // Initialisation du hook de navigation

  // États pour les couleurs
  const [backgroundStart, setBackgroundStart] = useState("#ffffff");
  const [backgroundEnd, setBackgroundEnd] = useState("#f7f7f7");
  const [textColor, setTextColor] = useState("#333333");
  const [headerColor, setHeaderColor] = useState("#000000");

  // Mettre à jour les variables CSS dynamiquement
  const updateColors = () => {
    document.documentElement.style.setProperty("--background-gradient-start", backgroundStart);
    document.documentElement.style.setProperty("--background-gradient-end", backgroundEnd);
    document.documentElement.style.setProperty("--text-color", textColor);
    document.documentElement.style.setProperty("--header-color", headerColor);
  };

  // Appeler updateColors chaque fois que les couleurs changent
  useEffect(() => {
    updateColors();
  }, [backgroundStart, backgroundEnd, textColor, headerColor]);

  // Définir les callbacks pour la navigation dans Navbar1
  const handleLinkOneTextClick = () => navigate("/mentoring");
  const handleLinkTwoTextClick = () => navigate("/chatbots");
  const handleLinkAltTextClick = () => navigate("/about-us");
  const handleLinkThreeTextClick = () => navigate("/other-services");
  const handleLinkBlogTextClick = () => navigate("/blog");
  const handleActionsContainerClick = () => navigate("/contact");

  return (
    <>
      <Navbar1
        contentBackground="linear-gradient(to right, #fff, #f7f7f7)"
        onColumnImageClick={() => navigate("/")}
        onLinkOneTextClick={handleLinkOneTextClick}
        onLinkTwoTextClick={handleLinkTwoTextClick}
        onLinkAltTextClick={handleLinkAltTextClick}
        onLinkThreeTextClick={handleLinkThreeTextClick}
        onLinkBlogTextClick={handleLinkBlogTextClick}
        onActionsContainerClick={handleActionsContainerClick}
      />
      <div className={styles.policyContainer}>
        <h1>Politique de Confidentialité</h1>
        <p>
          Chez Alpaga, la confidentialité de vos données personnelles est une priorité. Cette politique de 
          confidentialité décrit la manière dont nous collectons, utilisons et protégeons vos informations lorsque 
          vous visitez notre site web ou utilisez nos services. En accédant au site d'Alpaga (ci-après "le Site"), 
          vous acceptez les pratiques décrites dans cette politique.
        </p>
        <h2>1. Données Collectées</h2>
        <p>
          Nous collectons différents types de données lorsque vous interagissez avec nous via le Site :
        </p>
        <ul>
          <li>Données personnelles identifiables : telles que votre nom, adresse email, numéro de téléphone, lorsque vous remplissez un formulaire de contact ou vous inscrivez à notre newsletter.</li>
          <li>Données non personnelles : comme des informations sur votre navigation (adresse IP, type de navigateur, pages consultées), via des cookies ou technologies similaires.</li>
        </ul>
        <h2>2. Utilisation des Données</h2>
        <p>
          Les données collectées sont utilisées pour les finalités suivantes :
        </p>
        <ul>
          <li>Fournir et améliorer nos services.</li>
          <li>Répondre à vos demandes d'information.</li>
          <li>Envoyer des communications marketing (avec votre consentement).</li>
          <li>Analyser l'utilisation du Site pour améliorer l'expérience utilisateur.</li>
        </ul>
        <p>
          Nous ne vendons ni ne louons vos données à des tiers. Toutefois, nous pouvons partager vos données avec des partenaires de confiance qui nous aident à fournir nos services (ex : services d'hébergement).
        </p>
        <h2>3. Base Légale du Traitement</h2>
        <p>
          Nous traitons vos données sur les bases légales suivantes :
        </p>
        <ul>
          <li><strong>Consentement :</strong> lorsque vous nous donnez explicitement votre accord (ex. abonnement à la newsletter).</li>
          <li><strong>Exécution d'un contrat :</strong> lorsque le traitement est nécessaire pour la fourniture de services que vous avez demandés.</li>
          <li><strong>Intérêts légitimes :</strong> pour améliorer nos services, sécuriser le Site, ou pour des fins d’analyse statistique.</li>
        </ul>
        <h2>4. Conservation des Données</h2>
        <p>
          Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre les finalités pour lesquelles elles ont été collectées, ou pour nous conformer à nos obligations légales.
        </p>
        <h2>5. Droits des Utilisateurs</h2>
        <p>
          Conformément à la législation applicable (notamment le RGPD), vous disposez des droits suivants sur vos données personnelles :
        </p>
        <ul>
          <li><strong>Droit d'accès :</strong> Vous pouvez demander une copie des données que nous détenons sur vous.</li>
          <li><strong>Droit de rectification :</strong> Si vos données sont incorrectes, vous pouvez demander leur correction.</li>
          <li><strong>Droit à l'effacement :</strong> Vous pouvez demander la suppression de vos données.</li>
          <li><strong>Droit à la limitation :</strong> Vous pouvez demander la limitation du traitement de vos données.</li>
          <li><strong>Droit d'opposition :</strong> Vous pouvez vous opposer au traitement de vos données à des fins de marketing direct.</li>
          <li><strong>Droit à la portabilité :</strong> Vous pouvez demander à recevoir vos données dans un format structuré.</li>
        </ul>
        <p>
          Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante : <a href="mailto:email@alpaga.com">email@alpaga.com</a>. Nous nous engageons à répondre à votre demande dans un délai d’un mois.
        </p>
        <h2>6. Sécurité des Données</h2>
        <p>
          Nous prenons des mesures de sécurité appropriées pour protéger vos données contre l'accès non autorisé, la modification, la divulgation ou la destruction. Cela inclut des mesures techniques et organisationnelles telles que le cryptage des données et la limitation de l'accès à vos informations.
        </p>
        <h2>7. Cookies et Technologies Similaires</h2>
        <p>
          Le Site utilise des cookies pour améliorer votre expérience. Les cookies sont de petits fichiers stockés sur votre appareil qui nous permettent de collecter des informations sur votre utilisation du Site. Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur.
        </p>
        <h2>8. Partage de Données avec des Tiers</h2>
        <p>
          Nous ne partageons vos données personnelles avec des tiers que dans les circonstances suivantes :
        </p>
        <ul>
          <li>Avec des prestataires de services qui nous aident à exploiter le Site ou à fournir nos services (ex. hébergement, outils marketing).</li>
          <li>Si requis par la loi ou pour répondre à des procédures légales.</li>
          <li>Dans le cadre d'une fusion, acquisition ou vente d'actifs.</li>
        </ul>
        <h2>9. Transfert International de Données</h2>
        <p>
          Dans le cadre de la fourniture de nos services, vos données personnelles peuvent être transférées hors de votre pays de résidence, y compris vers des pays qui ne disposent pas d’une législation sur la protection des données équivalente à celle de votre juridiction. Dans ce cas, nous mettons en place des mesures appropriées pour garantir un niveau adéquat de protection.
        </p>
        <h2>10. Modifications de la Politique de Confidentialité</h2>
        <p>
          Alpaga se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications prendront effet dès leur publication sur le Site. Nous vous recommandons de consulter régulièrement cette page pour vous tenir informé des éventuelles mises à jour.
        </p>
        <h2>11. Contact</h2>
        <p>
          Pour toute question concernant cette politique de confidentialité, ou pour exercer vos droits, vous pouvez nous contacter à l'adresse suivante :
        </p>
        <address>
          Alpaga<br />
          Email : <a href="mailto:info@alpaga.ai">info@alpaga.ai</a>
        </address>
      </div>
      <Footer3 /> {/* Inclusion du Footer */}
    </>
  );
};

export default PrivacyPolicy;
