import { FunctionComponent } from "react";
import styles from "./Testimonial.module.css";

const Testimonial: FunctionComponent = () => {
  return (
    <section className={styles.testimonial1}>
      <div className={styles.content}>
        <img
          className={styles.logo2ColordarkIcon}
          loading="eager"
          alt=""
          src="/Logo_gift.png"
        />
        <h3 className={styles.quote}>
        Alpaga a transformé ma comptabilité en automatisant plusieurs processus. Un gain de temps énorme pour mon entreprise !
        </h3>
        <div className={styles.avatar}>
      
          <div className={styles.avatarContent}>
            <div className={styles.columnOneFooter}>Pierre</div>
            <div className={styles.columnOneFooter1}>
              CEO, GIFT inc.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
