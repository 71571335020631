import { FunctionComponent, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FAQ1.module.css";

const FAQ1: FunctionComponent = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    { question: "Qu'est-ce qu'Alpaga ?", answer: "Alpaga est une entreprise dédiée à aider les PME québécoises à maîtriser l'IA générative et les sciences des données pour améliorer leur performance." },
    { question: "Pourquoi choisir Alpaga ?", answer: "Alpaga aide à réduire les coûts et à optimiser les processus grâce à des solutions d'IA sur mesure, rendant votre entreprise plus efficace." },
    { question: "Quelle est la différence entre consultation et coaching ?", answer: "La consultation offre des solutions spécifiques à vos défis, tandis que le coaching vous guide pour développer vos compétences en IA et en données." },
    { question: "Quels sont les services de microsites ?", answer: "Alpaga conçoit des microsites personnalisés pour améliorer votre présence en ligne et attirer plus de clients potentiels." },
    { question: "Quels sont des exemples de solutions personnalisées ?", answer: "Alpaga développe des chatbots intelligents, des outils de gestion de factures, des tableaux de bord et d'autres applications sur mesure pour répondre à vos besoins spécifiques." }
  ];
  

  return (
    <div className={styles.faq2}>
      <div className={styles.sectionTitleWrapper}>
        <div className={styles.sectionTitle}>
          <h1 className={styles.faqs}>FAQs</h1>
        </div>
      </div>
      <div className={styles.accordion}>
        {faqs.map((faq, index) => (
          <div key={index} className={styles.accordionItem}>
            <div className={styles.divider} />
            <div className={styles.title} onClick={() => handleToggle(index)}>
              <b className={styles.question}>{faq.question}</b>
              <img
                className={styles.icon}
                loading="eager"
                alt=""
                src="/icon.svg"
              />
            </div>
            <div
              className={`${styles.answer} ${openIndex === index ? styles.open : ""}`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.contentArea}>
        <div className={styles.content}>
          <div className={styles.content1}>
            <h1 className={styles.stillHaveA}>Vous avez encore des questions?</h1>
            <div className={styles.accordionItem6}>{`Parlons-en! `}</div>
          </div>
          <button
            className={styles.stylesecondarySmallfalse}
            onClick={onStyleSecondarySmallFalseClick}
          >
            <div className={styles.button}>Contactez-nous</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FAQ1;
