import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Content2.module.css";

type Content2Type = {
  subheading?: string;
  heading?: string;
  heading1?: string;
  title?: string;
  heading2?: string;
  text?: string;
  heading3?: string;
  text1?: string;
  heading4?: string;
  privacyPolicyTermsCookies?: string;
  subheading1?: boolean;

  /** Style props */
  propWidth?: CSSProperties["width"];

  /** Action props */
  onStyleSecondarySmallFalseClick?: () => void;
};

const Content2: FunctionComponent<Content2Type> = ({
  subheading,
  heading,
  heading1,
  title,
  heading2,
  text,
  heading3,
  text1,
  heading4,
  privacyPolicyTermsCookies,
  subheading1,
  propWidth,
  onStyleSecondarySmallFalseClick,
}) => {
  const subheading1Style: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className={styles.content}>
      <div className={styles.column}>
        <div className={styles.content1}>
          {!subheading1 && (
            <div className={styles.subheading} style={subheading1Style}>
              {subheading}
            </div>
          )}
          <h1 className={styles.heading}>{heading}</h1>
        </div>
        <div className={styles.actions}>
          <button
            className={styles.stylesecondarySmallfalse}
            onClick={onStyleSecondarySmallFalseClick}
          >
            <div className={styles.button}>Commençons</div>
          </button>
          <div className={styles.stylelinkSmallfalseDark}>
            <div className={styles.button1}>Learn More</div>
            <img
              className={styles.iconChevronRight}
              alt=""
              src="/icon--chevronright.svg"
            />
          </div>
        </div>
      </div>
      <div className={styles.timeline}>
        <div className={styles.timelineItem}>
          <div className={styles.content2}>
            <img
              className={styles.iconRelume}
              loading="eager"
              alt=""
              src="/icon--relume-4.svg"
            />
            <div className={styles.divider} />
          </div>
          <div className={styles.content3}>
            <b className={styles.heading1}>{heading1}</b>
            <div className={styles.title}>{title}</div>
          </div>
        </div>
        <div className={styles.timelineItem1}>
          <div className={styles.content4}>
            <img
              className={styles.iconRelume1}
              alt=""
              src="/icon--relume-4.svg"
            />
            <div className={styles.divider1} />
          </div>
          <div className={styles.content5}>
            <b className={styles.heading2}>{heading2}</b>
            <div className={styles.text}>{text}</div>
          </div>
        </div>
        <div className={styles.timelineItem2}>
          <div className={styles.content6}>
            <img
              className={styles.iconRelume2}
              alt=""
              src="/icon--relume-4.svg"
            />
            <div className={styles.divider2} />
          </div>
          <div className={styles.content7}>
            <b className={styles.heading3}>{heading3}</b>
            <div className={styles.text1}>{text1}</div>
          </div>
        </div>
        <div className={styles.timelineItem3}>
          <div className={styles.content8}>
            <img
              className={styles.iconRelume3}
              alt=""
              src="/icon--relume-4.svg"
            />
          </div>
          <div className={styles.content9}>
            <b className={styles.heading4}>{heading4}</b>
            <div className={styles.privacyPolicyTerms}>
              {privacyPolicyTermsCookies}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content2;
