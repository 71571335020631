import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Content2 from "./Content2";
import styles from "./Layout7.module.css";

const Layout7: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStyleSecondarySmallFalseClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <section className={styles.layout121}>
      <Content2
        subheading="Simplifié"
        heading="Découvrir nos Services : Un Guide Étape par Étape"
        heading1="Consultation Initiale"
        title="Planifiez une consultation pour discuter de vos besoins et objectifs."
        heading2="Sélection des Services"
        text="Choisissez parmi notre gamme de services en IA et science des données."
        heading3="Mise en Œuvre"
        text1="Notre équipe travaillera avec vous pour mettre en œuvre les services choisis."
        heading4="Surveillance & Optimisation"
        privacyPolicyTermsCookies="Nous surveillons et optimisons continuellement les solutions mises en place."
        subheading1={false}
        propWidth="73px"
        onStyleSecondarySmallFalseClick={onStyleSecondarySmallFalseClick}
      />
    </section>
  );
};

export default Layout7;
