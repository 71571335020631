import { FunctionComponent } from "react";
import styles from "./Column2.module.css";

type Column2Type = {
  heading?: string;
  form?: string;
};

const Column2: FunctionComponent<Column2Type> = ({ heading, form }) => {
  return (
    <div className={styles.column}>
      <div className={styles.content}>
        <img className={styles.iconRelume} alt="" src="/icon--relume5.svg" />
        <div className={styles.sectionTitle}>
          <h3 className={styles.heading}>{heading}</h3>
          <div className={styles.form}>{form}</div>
        </div>
      </div>
      <div className={styles.action}>
        <div className={styles.stylelinkSmallfalseDark}>
          <div className={styles.button}>En savoir plus</div>
          <img
            className={styles.iconChevronRight}
            loading="eager"
            alt=""
            src="/icon--chevronright.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Column2;
