import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import Layout4 from "../components/Layout4";
import Layout3 from "../components/Layout3";
import Layout2 from "../components/Layout2";
import FAQ1 from "../components/FAQ1";
import CTA from "../components/CTA";
import Footer3 from "../components/Footer3";
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onLinkOneTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  const onLinkTwoTextClick = useCallback(() => {
    navigate("/chatbots");
  }, [navigate]);

  const onLinkAltTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkThreeTextClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkBlogTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onActionsContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onColumnOneTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkOneText2Click = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkTwoText2Click = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onLinkEightTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  useEffect(() => {
    if (location.hash === "#faq") {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight * 0.56,
          behavior: "smooth",
        });
      }, 100); // Délai de 100ms pour s'assurer que le contenu est chargé
    }
  }, [location]);

  return (
    <div className={styles.home}>
      <section className={styles.home1}>
        <Navbar1
          contentBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 100))"
          onLinkOneTextClick={onLinkOneTextClick}
          onLinkTwoTextClick={onLinkTwoTextClick}
          onLinkAltTextClick={onLinkAltTextClick}
          onLinkThreeTextClick={onLinkThreeTextClick}
          onLinkBlogTextClick={onLinkBlogTextClick}
          onActionsContainerClick={onActionsContainerClick}
        />
        <Layout4 />
        <Layout3 />
        <Layout2 />
        <FAQ1 />
        <CTA />
        <Footer3
          iconInstagram="/icon--instagram.svg"
          propBackgroundImage="url('/column4@3x.png')"
          onColumnOneTextClick={onColumnOneTextClick}
          onLinkOneText2Click={onLinkOneText2Click}
          onLinkTwoText2Click={onLinkTwoText2Click}
          onLinkEightTextClick={onLinkEightTextClick}
        />
      </section>
    </div>
  );
};

export default Home;
