import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Actions1 from "./Actions1";
import styles from "./CTA2.module.css";

const CTA2: FunctionComponent = () => {
  const navigate = useNavigate();

  const onStylePrimarySmallFalseDaClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <section className={styles.cta19}>
      <div className={styles.column}>
        <div className={styles.content}>
          <h1 className={styles.heading}>
            Découvrez Nos Services en IA et science des données
          </h1>
          <div className={styles.texttwo}>
            Libérez la Puissance de l'IA et de la Science des Données avec nos services complets.
          </div>
        </div>
        <Actions1
          button="Planifier"
          propOverflow="hidden"
          propPadding="var(--padding-xs) 15px"
          onStylePrimarySmallFalseDaClick={onStylePrimarySmallFalseDaClick}
        />
      </div>
    </section>
  );
};

export default CTA2;
