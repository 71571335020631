import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Actions1.module.css";

type Actions1Type = {
  button?: string;

  /** Style props */
  propOverflow?: CSSProperties["overflow"];
  propPadding?: CSSProperties["padding"];

  /** Action props */
  onStylePrimarySmallFalseDaClick?: () => void;
};

const Actions1: FunctionComponent<Actions1Type> = ({
  button,
  propOverflow,
  propPadding,
  onStylePrimarySmallFalseDaClick,
}) => {
  const stylePrimarySmallFalseDaStyle: CSSProperties = useMemo(() => {
    return {
      overflow: propOverflow,
    };
  }, [propOverflow]);

  const styleSecondarySmallFalseStyle: CSSProperties = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div className={styles.actions}>
      <button
        className={styles.styleprimarySmallfalseDa}
        onClick={onStylePrimarySmallFalseDaClick}
        style={stylePrimarySmallFalseDaStyle}
      >
        <div className={styles.button}>ICI</div>
      </button>
      <div
        className={styles.stylesecondarySmallfalse}
        style={styleSecondarySmallFalseStyle}
      >
        <div className={styles.button1}>{button}</div>
      </div>
    </div>
  );
};

export default Actions1;
