import { FunctionComponent } from "react";
import Column2 from "./Column2";
import styles from "./Layout8.module.css";

const Layout8: FunctionComponent = () => {
  return (
    <section className={styles.layout238}>
      <h1 className={styles.heading}>
        Services personnalisés en IA et en Science des Données pour les Clients Non-Techniques
      </h1>
      <div className={styles.row}>
        <Column2
          heading="Tableaux de Bord d'Analyse Avancée et de Visualisation de Données"
          form="Nous proposons une large gamme de services, y compris du coaching en IA générative, des chatbots, le nettoyage de données, et des solutions IA sur mesure."
        />
        <div className={styles.column}>
          <div className={styles.content}>
            <img
              className={styles.iconRelume}
              alt=""
              src="/icon--relume5.svg"
            />
            <div className={styles.sectionTitle}>
              <h3 className={styles.heading1}>
                Solutions IA sur mesure adaptées à vos besoins commerciaux
              </h3>
              <div className={styles.text}>
                <p className={styles.ourTeamOf}>
                  Notre équipe d'experts vous guidera à travers le processus de mise en œuvre de solutions IA qui stimulent la croissance de votre entreprise.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <div className={styles.stylelinkSmallfalseDark}>
              <div className={styles.button}>En savoir plus</div>
              <img
                className={styles.iconChevronRight}
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
        <Column2
          heading="Transformez vos Données en Informations Actionnables"
          form="Avec nos tableaux de bord d'analyse avancée et de visualisation de données, vous pouvez découvrir des informations précieuses et prendre des décisions éclairées."
        />
      </div>
    </section>
  );
};

export default Layout8;
