import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BlogArticles.module.css';
import client from './contentfulClient';

interface AssetFields {
  file: {
    url: string;
  };
  title?: string;
  description?: string;
}

interface Asset {
  sys: {
    id: string;
  };
  fields: AssetFields;
}

interface ArticleFields {
  title: string;
  slug?: string;
  description: string;
  content?: any;
  image?: Asset;
  date?: string;
  author?: string;
  authorImage?: Asset;
}

interface Article {
  sys: {
    id: string;
  };
  fields: ArticleFields;
}

const generateSlug = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '');
};

const BlogArticles: FunctionComponent = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    client
      .getEntries({
        content_type: 'article',
        order: ['-fields.date'],
      })
      .then((response) => {
        const articles = response.items.map((item) => {
          const fields = item.fields as Partial<ArticleFields>;
          const title = fields.title || `Titre manquant ${item.sys.id}`;
          const slug = fields.slug || generateSlug(title);
          const article: Article = {
            sys: item.sys,
            fields: {
              title: title,
              slug: slug,
              description: fields.description || "Description manquante",
              content: fields.content || "",
              image: fields.image,
              date: fields.date || "",
              author: fields.author || "Auteur inconnu",
              authorImage: fields.authorImage,
            },
          };
          return article;
        });
        setArticles(articles);
        setIsLoading(false);
      })
      .catch((err: Error) => {
        console.error(err);
        setError(err);
        setIsLoading(false);
      });
  }, []);

  const handleArticleClick = (slug: string, id: string) => {
    navigate(`/blog/${slug}/${id}`);
  };

  if (isLoading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>Une erreur s'est produite : {error.message}</p>;
  }

  return (
    <div className={styles.grid}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Apprenez, explorez et grandissez avec nous !
        </h1>
        <p className={styles.description}>
          Plongez dans le monde fascinant de l'IA et des technologies connexes à
          travers notre blog. Découvrez des articles captivants qui explorent les
          sujets les plus pertinents, des modèles de langage à la protection de la
          vie privée. Restez à la pointe de l'innovation et inspirez-vous des idées
          novatrices présentées dans notre blog.
        </p>
      </div>
      {articles.map((article) => (
        <div
          className={styles.card}
          key={article.sys.id}
          onClick={() => handleArticleClick(article.fields.slug || generateSlug(article.fields.title), article.sys.id)}
        >
          {article.fields.image && article.fields.image.fields.file.url && (
            <img
              className={styles.articleImage}
              src={`https:${article.fields.image.fields.file.url}`}
              alt={article.fields.title}
            />
          )}
          <div className={styles.articleContent}>
            <h2 className={styles.articleTitle}>{article.fields.title}</h2>
            <p className={styles.articleText}>{article.fields.description}</p>
            <div className={styles.authorInfo}>
              {article.fields.authorImage &&
                article.fields.authorImage.fields.file.url && (
                  <img
                    className={styles.profileImage}
                    src={`https:${article.fields.authorImage.fields.file.url}`}
                    alt={article.fields.author || 'Auteur'}
                  />
                )}
              {article.fields.date && (
                <span className={styles.date}>
                  {new Date(article.fields.date).toLocaleDateString('fr-FR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogArticles;
