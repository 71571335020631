import { FunctionComponent } from "react";
import styles from "./TimelineItem.module.css";

type TimelineItemType = {
  heading?: string;
  text?: string;
};

const TimelineItem: FunctionComponent<TimelineItemType> = ({
  heading,
  text,
}) => {
  return (
    <div className={styles.timelineItem}>
      <div className={styles.content}>
        <img className={styles.iconRelume} alt="" src="/icon--relume-4.svg" />
        <div className={styles.vectorsWrapper}>
          <div className={styles.divider} />
        </div>
      </div>
      <div className={styles.content1}>
        <b className={styles.heading}>{heading}</b>
        <div className={styles.columnCreditsDivider}>{text}</div>
      </div>
    </div>
  );
};

export default TimelineItem;
