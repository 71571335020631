import { FunctionComponent, ChangeEvent } from "react";
import styles from "./Column3.module.css";

type Column3Type = {
  radioName?: string;
  label?: string;
  radioName1?: string;
  label1?: string;
  radioName2?: string;
  label2?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Column3: FunctionComponent<Column3Type> = ({
  radioName,
  label,
  radioName1,
  label1,
  radioName2,
  label2,
  onChange,
}) => {
  return (
    <div className={styles.column}>
      <div className={styles.selectedfalse}>
        <input className={styles.radio} type="radio" name={radioName} value={label} onChange={onChange} />
        <div className={styles.label}>{label}</div>
      </div>
      <div className={styles.selectedfalse1}>
        <input className={styles.radio1} type="radio" name={radioName1} value={label1} onChange={onChange} />
        <div className={styles.label1}>{label1}</div>
      </div>
      <div className={styles.selectedfalse2}>
        <input className={styles.radio2} type="radio" name={radioName2} value={label2} onChange={onChange} />
        <div className={styles.label2}>{label2}</div>
      </div>
    </div>
  );
};

export default Column3;
