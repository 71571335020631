import { FunctionComponent, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import Form1 from "../components/Form1";
import Footer3 from "../components/Footer3";
import styles from "./Contact.module.css";

const Contact: FunctionComponent = () => {
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onFormSubmit = () => {
    setFormSubmitted(true);
  };

  const onColumnImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkOneTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  const onLinkTwoTextClick = useCallback(() => {
    navigate("/chatbots");
  }, [navigate]);

  const onLinkAltTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkThreeTextClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkBlogTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onActionsContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={styles.contact}>
      <Navbar1
        contentBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.9))"
        onColumnImageClick={onColumnImageClick}
        onLinkOneTextClick={onLinkOneTextClick}
        onLinkTwoTextClick={onLinkTwoTextClick}
        onLinkAltTextClick={onLinkAltTextClick}
        onLinkThreeTextClick={onLinkThreeTextClick}
        onLinkBlogTextClick={onLinkBlogTextClick} 
        onActionsContainerClick={onActionsContainerClick}
      />
      <section className={styles.contact2}>
        <div className={styles.sectionTitle}>
          {!formSubmitted && (
            <div className={styles.content}>
              <h1 className={styles.heading}>Contactez-nous</h1>
              <div className={styles.text}>Parlez-nous de votre projet!</div>
            </div>
          )}
        </div>
        <Form1 onFormSubmit={onFormSubmit} />
      </section>
      <Footer3 />
    </div>
  );
};

export default Contact;
