import { FunctionComponent } from "react";
import Card1 from "./Card1";
import styles from "./Team.module.css";

const Team: FunctionComponent = () => {
  return (
    <section className={styles.team6}>
      <div className={styles.sectionTitle}>
        <div className={styles.content}>
          <h1 className={styles.heading}>Notre Équipe</h1>
          <div className={styles.loremIpsumDolor}>
            Experts passionnés en IA générative et science des données.
          </div>
        </div>
      </div>
      <div className={styles.content1}>
        <div className={styles.content2}>
          <div className={styles.row}>
            <Card1
              placeholderImage="/anthony.png"
              name1="Anthony Nadon"
              jobTitle="CEO/ Fondateur / Dev"
              text="Avec dix ans d'expérience en gestion de projets multimédia et une expertise en science des données, Anthony allie curiosité insatiable et passion pour élever la littératie numérique, transformant ainsi l'accès à la technologie pour les PME et les particuliers."
              iconLinkedIn="/icon--linkedin.svg"
              iconX="/icon--x.svg"
              propFlex="1"
              propBorderRadius="90px"
              linkedInUrl="https://www.linkedin.com/in/anthonynadon/" // Lien LinkedIn
            />
            <Card1
              placeholderImage="/tommy.png"
              name1="Tommy Delorme"
              jobTitle="Consultant SEO"
              text="Tommy est l'as du SEO. Avec un portfolio impressionnant, il s'assure que nos services web soient optimisés pour dominer les résultats de recherche."
              iconLinkedIn="/icon--linkedin.svg"
              iconX="/icon--x.svg"
              propFlex="1"
              propBorderRadius="90px"
              linkedInUrl="https://www.linkedin.com/in/tommy-delorme-seo/" // Lien LinkedIn
            />
            <Card1
              placeholderImage="/placeholder-image-3@2x.png"
              name1="Arty"
              jobTitle="Chatbot"
              text="Notre atout le plus précieux, ce petit futé est bien plus qu'un simple chatbot."
              iconLinkedIn="/icon--linkedin.svg"
              iconX="/icon--x.svg"
              propFlex="1"
              propBorderRadius="90px"
              linkedInUrl="https://www.linkedin.com/company/alpaga-ia" // Lien LinkedIn
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
