import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar1 from "../components/Navbar1";
import Layout10 from "../components/Layout10";
import Team from "../components/Team";
import CTA2 from "../components/CTA2";
import Footer3 from "../components/Footer3";
import styles from "./AboutUs.module.css";

const AboutUs: FunctionComponent = () => {
  const navigate = useNavigate();

  const onColumnImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkOneTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  const onLinkTwoTextClick = useCallback(() => {
    navigate("/chatbots");
  }, [navigate]);

  const onLinkAltTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkThreeTextClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkBlogTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onActionsContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onColumnOneTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkOneText2Click = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkTwoText2Click = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const onLinkEightTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  return (
    <div className={styles.aboutUs}>
      <Navbar1
        contentBackground="linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgba(255, 226, 195, 0.9))"
        onColumnImageClick={onColumnImageClick}
        onLinkOneTextClick={onLinkOneTextClick}
        onLinkTwoTextClick={onLinkTwoTextClick}
        onLinkAltTextClick={onLinkAltTextClick}
        onLinkThreeTextClick={onLinkThreeTextClick}
        onLinkBlogTextClick={onLinkBlogTextClick} // Ajout de la propriété pour le blog
        onActionsContainerClick={onActionsContainerClick}
      />
      <Layout10 />
      <Team />
      <CTA2 />
      <Footer3
        iconInstagram="/icon--instagram.svg"
        propBackgroundImage="url('/column4@3x.png')"
        onColumnOneTextClick={onColumnOneTextClick}
        onLinkOneText2Click={onLinkOneText2Click}
        onLinkTwoText2Click={onLinkTwoText2Click}
        onLinkEightTextClick={onLinkEightTextClick}
      />
    </div>
  );
};

export default AboutUs;
