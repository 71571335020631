import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Layout9.module.css";

type Layout9Type = {
  heading?: string;
  contentFrame?: string;
  iconRelume?: string;
  subheadingOne?: string;
  formInputField?: string;
  iconRelume2?: string;
  subheadingTwo?: string;
  creditsDivider?: string;
  placeholderImage?: string;
  iconRelume1?: boolean;
  iconRelume3?: boolean;

  /** Style props */
  propHeight?: CSSProperties["height"];
};

const Layout9: FunctionComponent<Layout9Type> = ({
  heading,
  contentFrame,
  iconRelume,
  subheadingOne,
  formInputField,
  iconRelume2,
  subheadingTwo,
  creditsDivider,
  placeholderImage,
  iconRelume1,
  iconRelume3,
  propHeight,
}) => {
  const heading1Style: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
    };
  }, [propHeight]);

  return (
    <section className={styles.layout12}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.sectionTitle}>
            <h1 className={styles.heading} style={heading1Style}>
              {heading}
            </h1>
            <div className={styles.contentFrame}>{contentFrame}</div>
          </div>
          <div className={styles.list}>
            <div className={styles.listItem}>
              {!iconRelume1 && (
                <img className={styles.iconRelume} alt="" src={iconRelume} />
              )}
              <b className={styles.subheadingOne}>{subheadingOne}</b>
              <div className={styles.formInputField}>{formInputField}</div>
            </div>
            <div className={styles.listItem1}>
              {!iconRelume3 && (
                <img className={styles.iconRelume1} alt="" src={iconRelume2} />
              )}
              <b className={styles.subheadingTwo}>{subheadingTwo}</b>
              <div className={styles.creditsDivider}>{creditsDivider}</div>
            </div>
          </div>
        </div>
        <img
          className={styles.placeholderImageIcon}
          loading="eager"
          alt=""
          src={placeholderImage}
        />
      </div>
    </section>
  );
};

export default Layout9;
