import React, { FunctionComponent, useEffect, useState, useCallback, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar1 from "./Navbar1";
import Footer3 from "./Footer3";
import client from "./contentfulClient";
import styles from "./BlogArticleDetail.module.css";
import navbarStyles from "./Navbar1.module.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document, Block, BLOCKS } from "@contentful/rich-text-types";
import CTA from "../components/CTA";
import useWindowSize from "../hooks/useWindowSize"; // Importez le hook personnalisé

interface AssetFields {
  file: {
    url: string;
  };
}

interface ArticleFields {
  title: string;
  content: Document;
  image?: {
    fields: AssetFields;
  };
  date: string;
  author: string;
  authorImage?: {
    fields: AssetFields;
  };
}

interface Article {
  sys: {
    id: string;
  };
  fields: ArticleFields;
}

interface Heading {
  text: string;
  id: string;
}

const BlogArticleDetail: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [headings, setHeadings] = useState<Heading[]>([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [navbarClass, setNavbarClass] = useState(navbarStyles.navbarVisible);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [currentSection, setCurrentSection] = useState('');
  const headingRefs = useRef<{ [key: string]: HTMLElement | null }>({});
  const navigate = useNavigate();
  const windowSize = useWindowSize(); // Utilisez le hook pour obtenir la taille de la fenêtre
  const isSmallScreen = windowSize.width !== undefined && windowSize.width < 768;

  const [isTocOpen, setIsTocOpen] = useState(false); // État pour le menu de la table des matières

  const handleNavigation = useCallback(
    (path: string) => navigate(path),
    [navigate]
  );

  const extractHeadingsFromContent = (content: Document) => {
    const newHeadings: Heading[] = [];
    let headingIndex = 0;

    const traverse = (node: Block | any) => {
      if (node.nodeType === "heading-2") {
        const text = node.content.map((c: any) => c.value).join("");
        const id = `section-${headingIndex}`;
        newHeadings.push({ text, id });
        headingIndex++;
      }
      if (node.content) {
        node.content.forEach(traverse);
      }
    };

    traverse(content);
    setHeadings(newHeadings);
  };

  useEffect(() => {
    const fetchArticleById = async () => {
      try {
        const entry = (await client.getEntry(id!)) as unknown as { sys: any; fields: any };
        if (entry) {
          const fields = entry.fields as { [key: string]: any };
          const article: Article = {
            sys: entry.sys,
            fields: {
              title: fields.title || "Titre non disponible",
              content: fields.content,
              image: fields.image,
              date: fields.date || "",
              author: fields.author || "Auteur inconnu",
              authorImage: fields.authorImage,
            },
          };
          setArticle(article);
          extractHeadingsFromContent(fields.content);
        } else {
          setError(new Error("Article non trouvé"));
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchArticleById();
    }
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.body.scrollHeight - window.innerHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;

      // Mettre à jour l'état de la sidebar
      const sidebarVisible = scrollPercentage >= 6 && scrollPercentage <= 80;
      setShowSidebar(sidebarVisible);

      // Mettre à jour la classe de la navbar après 5% de défilement
      if (scrollPercentage > 5 && scrollTop > lastScrollTop) {
        setNavbarClass(navbarStyles.navbarHidden);
      } else if (scrollTop < lastScrollTop) {
        setNavbarClass(navbarStyles.navbarVisible);
      }

      // Mettre à jour la position de défilement pour le prochain appel
      setLastScrollTop(scrollTop);
    };

    const onScroll = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [lastScrollTop]);

  // Utiliser IntersectionObserver pour détecter la section visible
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '-300px',
      threshold: 0.8,
    };

    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          const target = entry.target as HTMLElement;
          setCurrentSection(target.id);
        }
      });
    };

    const observer = new IntersectionObserver(callback, observerOptions);

    Object.values(headingRefs.current).forEach((heading) => {
      if (heading) {
        observer.observe(heading);
      }
    });

    return () => {
      Object.values(headingRefs.current).forEach((heading) => {
        if (heading) {
          observer.unobserve(heading);
        }
      });
    };
  }, [headings]);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const customRenderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode) => {
        const headingIndex = headings.findIndex(
          (heading) => heading.text === node.content[0].value
        );
        const id = headings[headingIndex]?.id || `section-${headingIndex}`;
        return (
          <h2
            id={id}
            className={styles.heading}
            ref={(el) => {
              if (el) {
                headingRefs.current[id] = el;
              }
            }}
          >
            {children}
          </h2>
        );
      },
    },
  };

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  if (isLoading) return <p>Chargement...</p>;
  if (error || !article) {
    return <p>Une erreur s'est produite ou l'article n'a pas été trouvé.</p>;
  }

  const articleTitle = article.fields.title;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(articleTitle)}`;

  const bannerImageUrl = article.fields.image?.fields.file.url;

  return (
    <div className={styles.pageContainer}>
      <Navbar1
        className={navbarClass}
        onColumnImageClick={() => handleNavigation("/")}
        onLinkOneTextClick={() => handleNavigation("/mentoring")}
        onLinkTwoTextClick={() => handleNavigation("/chatbots")}
        onLinkAltTextClick={() => handleNavigation("/about-us")}
        onLinkThreeTextClick={() => handleNavigation("/other-services")}
        onLinkBlogTextClick={() => handleNavigation("/blog")}
        onActionsContainerClick={() => handleNavigation("/contact")}
      />

      {/* Afficher la sidebar uniquement si l'écran n'est pas petit */}
      {!isSmallScreen && (
        <aside className={`${styles.sidebar} ${showSidebar ? styles.fadeInOut : ""}`}>
          <nav>
            <ul>
              {headings.map((heading) => (
                <li key={heading.id}>
                  <a
                    href={`#${heading.id}`}
                    className={`${styles.sidebarLink} ${
                      currentSection === heading.id ? styles.activeLink : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(heading.id);
                    }}
                  >
                    {heading.text}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      )}

      <div className={styles.contentCentered}>
        <div className={styles.titleImageContainer}>
          <h1 className={styles.articleTitleCentered}>{article.fields.title}</h1>
          {bannerImageUrl && (
            <div className={styles.imageContainer}>
              <img
                src={`https:${bannerImageUrl}`}
                alt={article.fields.title}
                className={styles.bannerImage}
              />
            </div>
          )}
        </div>
        <div className={styles.articleTextCentered}>
          {documentToReactComponents(article.fields.content, customRenderOptions)}
        </div>

        {/* Boutons de partage */}
        <div className={styles.shareButtonsContainer}>
          
          <a href={linkedInShareUrl} target="_blank" rel="noopener noreferrer">
            <img src="/icon--linkedin.svg" alt="Partager sur LinkedIn" />
          </a>
          <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer">
            <img src="/icon--facebook.svg" alt="Partager sur Facebook" />
          </a>
          <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
            <img src="/icon--x.svg" alt="Partager sur X" />
          </a>
        </div>
      </div>
      <CTA />
      <Footer3
        iconInstagram="/icon--instagram.svg"
        onColumnOneTextClick={() => handleNavigation("/about-us")}
        onLinkOneText2Click={() => handleNavigation("/other-services")}
        onLinkTwoText2Click={() => handleNavigation("/contact")}
        onLinkEightTextClick={() => handleNavigation("/mentoring")}
      />
    </div>
  );
};

export default BlogArticleDetail;
