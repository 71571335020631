import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import du hook de navigation
import Footer3 from "../components/Footer3";
import Navbar1 from "../components/Navbar1";
import styles from "./TermsOfUse.module.css";

const TermsOfUse: React.FC = () => {
  const navigate = useNavigate(); // Initialisation du hook de navigation

  // États pour les couleurs
  const [backgroundStart, setBackgroundStart] = useState("#ffffff");
  const [backgroundEnd, setBackgroundEnd] = useState("#f7f7f7");
  const [textColor, setTextColor] = useState("#333333");
  const [headerColor, setHeaderColor] = useState("#000000");

  // Mettre à jour les variables CSS dynamiquement
  const updateColors = () => {
    document.documentElement.style.setProperty("--background-gradient-start", backgroundStart);
    document.documentElement.style.setProperty("--background-gradient-end", backgroundEnd);
    document.documentElement.style.setProperty("--text-color", textColor);
    document.documentElement.style.setProperty("--header-color", headerColor);
  };

  // Appeler updateColors chaque fois que les couleurs changent
  useEffect(() => {
    updateColors();
  }, [backgroundStart, backgroundEnd, textColor, headerColor]);

  // Définir les callbacks pour la navigation dans Navbar1
  const handleLinkOneTextClick = () => navigate("/mentoring");
  const handleLinkTwoTextClick = () => navigate("/chatbots");
  const handleLinkAltTextClick = () => navigate("/about-us");
  const handleLinkThreeTextClick = () => navigate("/other-services");
  const handleLinkBlogTextClick = () => navigate("/blog");
  const handleActionsContainerClick = () => navigate("/contact");

  return (
    <>
      <Navbar1
        contentBackground="linear-gradient(to right, #fff, #f7f7f7)"
        onColumnImageClick={() => navigate("/")}
        onLinkOneTextClick={handleLinkOneTextClick}
        onLinkTwoTextClick={handleLinkTwoTextClick}
        onLinkAltTextClick={handleLinkAltTextClick}
        onLinkThreeTextClick={handleLinkThreeTextClick}
        onLinkBlogTextClick={handleLinkBlogTextClick}
        onActionsContainerClick={handleActionsContainerClick}
      />
      <div className={styles.termsContainer}>
        <h1>Termes et Conditions d'Utilisation</h1>
        <p>
          Bienvenue sur le site web d'Alpaga. En accédant et en utilisant ce Site, 
          vous acceptez de respecter les termes et conditions suivants. Si vous n'acceptez pas ces conditions, 
          veuillez ne pas utiliser le Site.
        </p>
        <h2>1. Utilisation du Site</h2>
        <p>
          Le Site est fourni par Alpaga pour offrir des informations sur nos services en intelligence 
          artificielle et en science des données. Toute utilisation du contenu, des services ou des outils 
          disponibles sur le Site doit se conformer aux présentes conditions.
        </p>
        <h2>2. Propriété Intellectuelle</h2>
        <p>
          Tous les contenus disponibles sur le Site, y compris, mais sans s'y limiter, les textes, images, 
          graphiques, logos, icônes, et logiciels, sont la propriété exclusive d'Alpaga ou de ses concédants 
          de licence. Toute reproduction, distribution, modification ou utilisation de ce contenu, en tout 
          ou en partie, est strictement interdite sans l'autorisation préalable écrite d'Alpaga.
        </p>
        <h2>3. Protection des Données</h2>
        <p>
          Alpaga s'engage à protéger la confidentialité de vos données personnelles conformément à notre 
          <a href="/privacy-policy">Politique de Confidentialité</a>. En utilisant le Site, vous consentez à 
          la collecte et à l'utilisation de vos données conformément à cette politique.
        </p>
        <h2>4. Limitation de Responsabilité</h2>
        <p>
          Alpaga met tout en œuvre pour assurer l'exactitude et la mise à jour des informations sur le Site. 
          Toutefois, Alpaga ne peut garantir que les informations sont toujours exactes, complètes ou à jour. 
          En aucun cas, Alpaga ne pourra être tenu responsable de dommages directs ou indirects résultant de 
          l'utilisation du Site ou des informations qu'il contient.
        </p>
        <h2>5. Liens vers des Sites Tiers</h2>
        <p>
          Le Site peut contenir des liens vers des sites web tiers qui ne sont pas sous le contrôle d'Alpaga. 
          Alpaga ne peut être tenu responsable du contenu ou des pratiques de confidentialité de ces sites tiers. 
          Nous vous conseillons de lire les politiques de confidentialité et les termes d'utilisation de ces sites.
        </p>
        <h2>6. Modifications des Termes et Conditions</h2>
        <p>
          Alpaga se réserve le droit de modifier à tout moment les présentes conditions d'utilisation. Toute 
          modification sera effective dès sa publication sur le Site. Il est de votre responsabilité de consulter 
          régulièrement cette page pour être informé des mises à jour.
        </p>
        <h2>7. Utilisation des Services d'Alpaga</h2>
        <p>
          Les services d'Alpaga en intelligence artificielle et en automatisation sont fournis conformément aux 
          contrats spécifiques conclus avec chaque client. Pour plus d'informations sur nos services et les 
          conditions qui les régissent, veuillez contacter notre équipe via la page contact.
        </p>
        <h2>8. Droit Applicable</h2>
        <p>
          Les présentes conditions sont régies par les lois en vigueur dans le pays d'implantation d'Alpaga. 
          Tout litige relatif à l'utilisation du Site sera soumis à la juridiction exclusive des tribunaux compétents de ce pays.
        </p>
        <h2>9. Contact</h2>
        <p>
          Pour toute question concernant ces termes et conditions, vous pouvez nous contacter à l'adresse suivante :
        </p>
        <address>
          Alpaga<br />
          Email : <a href="mailto:info@alpaga.ai">info@alpaga.ai</a>
        </address>
      </div>
      <Footer3 /> {/* Inclusion du Footer */}
    </>
  );
};

export default TermsOfUse;
