import { FunctionComponent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from '@react-spring/web';
import styles from "./Pricing.module.css";

type PlanType = 'Débutant' | 'Intermédiaire' | 'Avancé';

const Pricing: FunctionComponent = () => {
  const navigate = useNavigate();

  const [selectedPlan, setSelectedPlan] = useState<PlanType>('Débutant');
  const [activeButtonRef, setActiveButtonRef] = useState<HTMLButtonElement | null>(null);

  const plans: Record<PlanType, { price: number; features: string[] }> = {
    Débutant: {
      price: 490,
      features: [
        "Introduction à l’IA générative et historique",
        "Familiarisation avec le jargon",
        "Présentation de ChatGPT et autres outils",
        "Exercices pratiques de rédaction de prompts efficaces",
        "Études de cas sur les applications dans la vie quotidienne",
        "Démonstration en direct",
        "Exploration des opportunités offertes par l’IA générative",
        "Exemples concrets de bonnes pratiques et éthique",
        "Développement d'attitudes et compétences pour être prêt pour l'avenir",
        "6 heures de formation"
      ]
    },
    Intermédiaire: {
      price: 590,
      features: [
        "Compréhension du fonctionnement interne de l’IA générative, incluant les réseaux neuronaux et les algorithmes",
        "Exercices pratiques pour maîtriser la structure d’un prompt",
        "Utilisation de ChatGPT pour la création de contenu, la recherche et la stratégie",
        "Comparaison des différents modèles et outils comme Anthropic, Perplexity, Gemini",
        "Personnalisation de ChatGPT avec Custom Instructions",
        "Introduction aux formats de données structurées et non structurées, avec exercices de manipulation",
        "Introduction aux principes des API",
        "Introduction à l’open source, ses avantages et contributions",
        "Démonstration en direct",
        "Études de cas sur les bonnes pratiques et l'éthique",
        "6 heures de formation"
      ]
    },
    Avancé: {
      price: 690,
      features: [
        "Exploration des options locales en IA générative avec exemples d'implémentation",
        "Projets pratiques de développement avec les outils d’IA générative",
        "Introduction à l’architecture d’un agent IA, avec explication des composants et projet de construction",
        "Principes de grounding et intégration de données personnelles, incluant RAG (Retrieval-Augmented Generation)",
        "Utilisation avancée des API avec démonstrations concrètes",
        "Introduction au function calling avec exemples d'utilisation dans des projets réels",
        "Optimisation des modèles avec fine-tuning et projets pratiques",
        "Discussions sur les dernières recherches et développements en bonnes pratiques et éthique",
        "Applications des concepts avancés avec démonstration en direct",
        "6 heures de formation"
      ]
    }
  };

  const onStylePrimarySmallFalseDaClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  const splitFeatures = (features: string[]) => {
    const mid = Math.ceil(features.length / 2);
    return [features.slice(0, mid), features.slice(mid)];
  };

  const [leftFeatures, rightFeatures] = splitFeatures(plans[selectedPlan].features);

  const { price } = useSpring({
    from: { price: 0 },
    price: plans[selectedPlan].price,
    config: {
      duration: 400, // Ajustez la durée de l'animation (en millisecondes)
      easing: (t) => t * (2 - t) // Fonction d'easing pour "ease-in-out"
    }
  });

  const handleClick = (plan: PlanType, buttonRef: HTMLButtonElement) => {
    setSelectedPlan(plan);
    setActiveButtonRef(buttonRef);
  };

  return (
    <section className={styles.pricing4}>
      <div className={styles.sectionTitle}>
        <div className={styles.subheading}>Découvrez l'IA générative avec nos</div>
        <div className={styles.content}>
          <h1 className={styles.heading}>Forfaits</h1>
          <div className={styles.textcontent}>
            {`Adaptés à votre niveau d'expertise.`}
          </div>
          <div className={styles.buttonGroup}>
            <button
              className={`${styles.levelButton} ${selectedPlan === 'Débutant' ? styles.active : ''}`}
              onClick={(e) => handleClick('Débutant', e.currentTarget)}
            >
              Débutant
            </button>
            <button
              className={`${styles.levelButton} ${selectedPlan === 'Intermédiaire' ? styles.active : ''}`}
              onClick={(e) => handleClick('Intermédiaire', e.currentTarget)}
            >
              Intermédiaire
            </button>
            <button
              className={`${styles.levelButton} ${selectedPlan === 'Avancé' ? styles.active : ''}`}
              onClick={(e) => handleClick('Avancé', e.currentTarget)}
            >
              Avancé
            </button>
          </div>
        </div>
      </div>
      <div className={styles.content1}>
        <div className={styles.priceTitle}>
          <div className={styles.priceTitle1}>
            <img
              className={styles.iconRelume}
              loading="eager"
              alt=""
              src="/icon--relume-4.svg"
            />
            <div className={styles.title}>
              <h3 className={styles.heading1}>{`Formation ${selectedPlan.toLowerCase()}`}</h3>
              <div className={styles.linkthreefooter}>
                {selectedPlan === 'Débutant' ? 'Parfait pour éveiller votre curiosité.' : selectedPlan === 'Intermédiaire' ? 'Parfait pour approfondir vos connaissances.' : 'Parfait pour maîtriser l\'IA.'}
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <animated.b className={styles.price}>
              {price.to((val) => `$${val.toFixed(0)}`)}
            </animated.b>
            <div className={styles.priceSubtitle}>*PAR PERSONNE</div>
            <b className={styles.price1} />
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.content2}>
          <div className={styles.typedefaultplaceholder}>Inclus:</div>
          <div className={styles.featureWrapper}>
            <div className={styles.featureColumn}>
              <ul className={styles.featureList}>
                {leftFeatures.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
            <div className={styles.featureColumn}>
              <ul className={styles.featureList}>
                {rightFeatures.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <button
          className={styles.styleprimarySmallFalseDa}
          onClick={onStylePrimarySmallFalseDaClick}
        >
          <div className={styles.button}>Contactez-nous!</div>
        </button>
      </div>
    </section>
  );
};

export default Pricing;
