import { FunctionComponent } from "react";
import styles from "./Layout6.module.css";

const Layout6: FunctionComponent = () => {
  return (
    <div className={styles.layout4}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content1}>
            <div className={styles.sectionTitle}>
              <div className={styles.subheading}>Efficace</div>
              <div className={styles.content2}>
                <h1 className={styles.heading}>
                  Rencontrez Arty, notre Chatbot Piloté par l'IA
                </h1>
                <div className={styles.timelineContent}>
                  Nos chatbots pilotés par l'IA assurent un service clientèle 24/7, gèrent
                  les tâches répétitives et offrent des réponses instantanées. Avec leurs
                  capacités avancées, ils révolutionnent la manière dont les entreprises
                  interagissent avec leurs clients, améliorant ainsi l'efficacité et la
                  satisfaction client.
                </div>
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listItem}>
                <b className={styles.subheadingOne}>24/7</b>
                <div className={styles.footerLinksColumn}>
                  Ne manquez jamais une demande de client avec nos chatbots alimentés par l'IA
                  disponibles tout le temps.
                </div>
              </div>
              <div className={styles.listItem1}>
                <b className={styles.subheadingTwo}>Sans effort</b>
                <div className={styles.text}>
                  Automatisez les tâches répétitives et libérez le temps de votre équipe pour
                  des tâches plus importantes.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.stylesecondarySmallfalse}>
              <div className={styles.button}>En savoir plus</div>
            </div>
            <div className={styles.stylelinkSmallfalseDark}>
              <div className={styles.button1}>S'inscrire</div>
              <img
                className={styles.iconChevronRight}
                alt=""
                src="/icon--chevronright.svg"
              />
            </div>
          </div>
        </div>
        <img
          className={styles.placeholderImageIcon}
          loading="eager"
          alt=""
          src="/placeholder-image-11@2x.png"
        />
      </div>
    </div>
  );
};

export default Layout6;
