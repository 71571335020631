import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import NavbarNight from "../components/NavbarNight";
import BlogArticles from "../components/BlogArticles";
import Footer4 from "../components/Footer4";
import styles from "./Blog.module.css";

const Blog: FunctionComponent = () => {
  const navigate = useNavigate();

  const onColumnImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onLinkOneTextClick = useCallback(() => {
    navigate("/mentoring");
  }, [navigate]);

  const onLinkTwoTextClick = useCallback(() => {
    navigate("/chatbots");
  }, [navigate]);

  const onLinkAltTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onLinkThreeTextClick = useCallback(() => {
    navigate("/other-services");
  }, [navigate]);

  const onLinkBlogTextClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);

  const onActionsContainerClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className={styles.blog}>
      <div className={styles.navbar}>
        <NavbarNight
          contentBackground="var(--night-background)"
          onColumnImageClick={onColumnImageClick}
          onLinkOneTextClick={onLinkOneTextClick}
          onLinkTwoTextClick={onLinkTwoTextClick}
          onLinkAltTextClick={onLinkAltTextClick}
          onLinkThreeTextClick={onLinkThreeTextClick}
          onLinkBlogTextClick={onLinkBlogTextClick}
          onActionsContainerClick={onActionsContainerClick}
        />
      </div>
      <div className={styles.blogContent}>
        <BlogArticles />
      </div>
      <Footer4
        iconInstagram="/icon--instagram_white.svg"
        propBackgroundImage="url('/logoAlpaga_white.png')"
      />
    </div>
  );
};

export default Blog;
